import axios from "axios";
import { setAlert } from "../alert";
import setAuthToken from "../../utils/setAuthToken";
import { logout } from "../auth";
import { v4 as uuid } from "uuid";

import {
  CALC_CLEARALL,
  CALC_CONSTMODAL_CLOSE,
  CALC_CONSTMODAL_OPEN,
  CALC_CONST_ADD,
  CALC_CONST_DELETE,
  CALC_CONST_EDIT,
  CALC_GET_ALL,
  CALC_LOADING,
  CALC_MODAL_IMPORT_CLOSE,
  CALC_MODAL_IMPORT_OPEN,
  CALC_NEWMODAL_CLOSED,
  CALC_NEWMODAL_OPEN,
  CALC_NEWOUTPUTMODAL_CLOSE,
  CALC_NEWOUTPUTMODAL_OPEN,
  CALC_NEWSTEPMODAL_CLOSE,
  CALC_NEWSTEPMODAL_OPEN,
  CALC_OUTPUT_ADD,
  CALC_OUTPUT_DELETE,
  CALC_STEP_ADD,
  CALC_STEP_DELETE,
  CALC_STEP_EDIT,
} from "../types";

// get all the calcs from the backend
export const LoadAllCalcs = () => async (dispatch) => {
  dispatch({
    type: CALC_LOADING,
    payload: true,
  });

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get("/api/config/calculations", {
      token: localStorage.token,
    });

    dispatch({
      type: CALC_GET_ALL,
      payload: res.data,
    });

    dispatch({
      type: CALC_LOADING,
      payload: false,
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }

    dispatch({
      type: CALC_LOADING,
      payload: false,
    });
  }
};

// create new modal open
export const createNewModalOpen = (record) => (dispatch) => {
  dispatch({
    type: CALC_NEWMODAL_OPEN,
    payload: record,
  });
};

// create new modal close
export const createNewModalClose = () => (dispatch) => {
  dispatch({
    type: CALC_NEWMODAL_CLOSED,
  });
};

// create new modal open for the calculation steps
export const stepModalOpen = (record) => (dispatch) => {
  dispatch({
    type: CALC_NEWSTEPMODAL_OPEN,
    payload: record,
  });
};

// create new modal close for the calculation steps
export const stepModalClose = () => (dispatch) => {
  dispatch({
    type: CALC_NEWSTEPMODAL_CLOSE,
  });
};

// create new modal open for the constants
export const constantModalOpen = (data) => (dispatch) => {
  dispatch({
    type: CALC_CONSTMODAL_OPEN,
    payload: data,
  });
};

// create new modal close for the constants
export const constantModalClose = () => (dispatch) => {
  dispatch({
    type: CALC_CONSTMODAL_CLOSE,
  });
};

export const ModalOpenImportCalculation = () => (dispatch) => {
  dispatch({
    type: CALC_MODAL_IMPORT_OPEN,
  });
};

export const ModalCloseImportCalculation = () => (dispatch) => {
  dispatch({
    type: CALC_MODAL_IMPORT_CLOSE,
  });
};

// create new modal open for the outputs steps
export const outputModalOpen = (record) => (dispatch) => {
  dispatch({
    type: CALC_NEWOUTPUTMODAL_OPEN,
    payload: record,
  });
};

// create new modal close for the outputs steps
export const outputModalClose = () => (dispatch) => {
  dispatch({
    type: CALC_NEWOUTPUTMODAL_CLOSE,
  });
};

// add constant to the calc
export const constantAdd =
  (data, edit = false) =>
  (dispatch) => {
    const key = uuid();
    let constAdd = { ...data };

    if (edit === false) constAdd.key = key;

    dispatch({
      type: CALC_CONST_ADD,
      payload: { data: constAdd, edit },
    });
  };

// Edit the Constants section of the calculations only
export const constantEdit = (data) => (dispatch) => {
  dispatch({
    type: CALC_CONST_EDIT,
    payload: data,
  });
};
// add constant to the calc
export const constantDelete = (data) => (dispatch) => {
  dispatch({
    type: CALC_CONST_DELETE,
    payload: data,
  });
};

// Adds a new output
export const outputAdd = (data) => (dispatch) => {
  const key = uuid();

  dispatch({
    type: CALC_OUTPUT_ADD,
    payload: { ...data, key },
  });
};

// removes an output
export const outputDelete = (data) => (dispatch) => {
  dispatch({
    type: CALC_OUTPUT_DELETE,
    payload: data,
  });
};

// Edits the steps part of the calculation
export const stepEdit = (data) => (dispatch) => {
  dispatch({
    type: CALC_STEP_EDIT,
    payload: data,
  });
};

// add constant to the calc
export const stepAdd = (data) => (dispatch) => {
  const key = uuid();

  dispatch({
    type: CALC_STEP_ADD,
    payload: { ...data, key },
  });
};

// add constant to the calc
export const stepDelete = (data) => (dispatch) => {
  dispatch({
    type: CALC_STEP_DELETE,
    payload: data,
  });
};

// update the backend to save the calculations
export const saveCalculation = (data) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    await axios.post("/api/config/calculations", { data: { ...data } });

    dispatch({
      type: CALC_GET_ALL,
      payload: null,
    });

    setTimeout(async () => {
      dispatch(LoadAllCalcs());

      dispatch(setAlert("Calculation Saved!!", "success"));
    }, 100);
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      dispatch(logout());
      dispatch(
        setAlert(
          "Your session has timed out, please log in and try again",
          "error"
        )
      );
    } else {
      dispatch(setAlert("An error has occured, please check logs", "error"));
    }

    dispatch({
      type: CALC_CLEARALL,
    });
  }
};

// update the backend to save the value
export const deleteCalculation = (record) => async (dispatch) => {
  dispatch({
    type: CALC_LOADING,
    payload: true,
  });

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    // delete the record
    await axios.delete("/api/config/calculations/" + record._id);

    // clear the tablet
    dispatch({
      type: CALC_GET_ALL,
      payload: null,
    });

    dispatch(LoadAllCalcs());
    dispatch(setAlert("Calculation deleted!!", "success"));
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      dispatch(logout());
      dispatch(
        setAlert(
          "Your session has timed out, please log in and try again",
          "error"
        )
      );
    } else {
      dispatch(setAlert("An error has occured, please check logs", "error"));
    }

    dispatch({
      type: CALC_CLEARALL,
    });
  }
};

// clear the calcs store
export const ClearAllCalcs = () => (dispatch) => {
  dispatch({
    type: CALC_CLEARALL,
  });
};
