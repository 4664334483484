import React, { Fragment, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd
import { Modal, Form, Input, Tooltip, Button, message } from "antd";
import { PlusCircleOutlined, ExportOutlined } from "@ant-design/icons";

// external functions
import {
  createNewModalClose,
  stepModalOpen,
  saveCalculation,
  constantModalOpen,
  outputModalOpen,
} from "../../../../../../actions/staffTools/calculations";
import { loadAllTypesDetailed } from "../../../../../../actions/staffTools/types";

// external components
import StepsModal from "./newStepModal";
import ConstantModal from "./newConstModal";
import OutputModal from "./newOutputModal";
import StepsTable from "../createNewStepsTable";
import ConstantTable from "../createNewConstTable";
import OutputsTable from "../outputTable";

// actual component
const ModalCreateCalc = ({
  modalTempCalcVis,
  createNewModalClose,
  newCalculation,
  stepModalOpen,
  constantModalOpen,
  saveCalculation,
  outputModalOpen,
  loadAllTypesDetailed,
  tabSelected,
}) => {
  const isEditing = useRef(null);
  const isLoaded = useRef(false);
  const [formData] = Form.useForm();

  useEffect(() => {
    if (modalTempCalcVis) {
      loadAllTypesDetailed();
    }
  }, [modalTempCalcVis, loadAllTypesDetailed]);

  if (modalTempCalcVis) {
    if (isLoaded.current === false) {
      isLoaded.current = true;

      if (newCalculation !== null) {
        formData.setFieldsValue({
          calculationName: newCalculation.calculationName,
          calculationDescription: newCalculation.calculationDescription,
        });
        isEditing.current = true;
      }
    }
  }

  const ClosingSequence = () => {
    formData.resetFields();
    isLoaded.current = false;
    isEditing.current = false;
    createNewModalClose(false);
  };

  const handleOk = () => {
    const FormInfo = formData.getFieldsValue();

    if (!Array.isArray(newCalculation?.constant)) {
      return message.error("Calculation Needs at least 1 constant/input");
    }
    if (newCalculation?.constant.length === 0) {
      return message.error("Calculation Needs at least 1 constant/input");
    }

    if (!Array.isArray(newCalculation?.outputs)) {
      return message.error("Calculation Needs at least 1 output");
    }
    if (newCalculation?.outputs.length === 0)
      return message.error("Calculation Needs at least 1 output");

    if (!Array.isArray(newCalculation?.steps)) {
      return message.error("Calculation Needs at least 1 step");
    }
    if (newCalculation?.steps.length === 0)
      return message.error("Calculation Needs at least 1 step");

    let newSaveCalculation = {
      ...newCalculation,
      ...FormInfo,
      ...(tabSelected === "1"
        ? {
            DeviceType: true,
          }
        : tabSelected === "3"
        ? { isVirtual: true }
        : {
            StatsType: true,
          }),
    };
    saveCalculation(newSaveCalculation);

    setTimeout(() => {
      ClosingSequence();
    }, 100);
  };

  const handleCancel = () => {
    ClosingSequence();
  };

  const exportData = () => {
    const FormInfo = formData.getFieldsValue();

    let newSaveCalculation = {
      ...newCalculation,
      ...FormInfo,
      ...(tabSelected === "1"
        ? {
            DeviceType: true,
          }
        : tabSelected === "3"
        ? { isVirtual: true }
        : {
            StatsType: true,
          }),
    };

    if (newCalculation.localcreate) delete newCalculation.localcreate;

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(newSaveCalculation)
    )}`;

    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `Calc Export - ${
      FormInfo?.calculationName
    } - ${new Date().toISOString()}.json`;

    link.click();
  };

  return (
    <Modal
      title={
        isEditing.current === true
          ? "Editing calculation"
          : tabSelected === "1"
          ? "Create new device calcaulation"
          : tabSelected === "2"
          ? "Create new stats calculation"
          : "Create new Virtual device"
      }
      visible={modalTempCalcVis}
      onCancel={handleCancel}
      width={900}
      footer={[
        ...(isEditing.current === true
          ? [
              <Button
                key="exportMe"
                onClick={() => exportData()}
                style={{ marginRight: 20 }}
                icon={<ExportOutlined />}
              >
                Export Me
              </Button>,
            ]
          : []),
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
        <Button
          key="save"
          onClick={() => formData.validateFields().then(() => handleOk())}
          type="primary"
        >
          {isEditing.current === true ? "Update" : "Save"}
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 10 }}
        form={formData}
        size="small"
      >
        <Form.Item
          label="Name"
          name="calculationName"
          tooltip="The name for this calculation"
          style={{ marginBottom: "5px" }}
          rules={[
            {
              required: true,
              message: `Can't be blank`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="calculationDescription">
          <Input.TextArea
            autoSize={{ minRows: 3, maxRows: 4 }}
            showCount
            maxLength={150}
          />
        </Form.Item>
      </Form>

      <Fragment>
        <Tooltip title="Add a new output to the list">
          <Button onClick={() => outputModalOpen(null)}>
            <PlusCircleOutlined />
            Calculation Output
          </Button>
        </Tooltip>

        <OutputModal />

        <OutputsTable />
      </Fragment>

      <Tooltip title="Add a new constant or input to the list">
        <Button onClick={() => constantModalOpen(null)}>
          <PlusCircleOutlined />
          Constant/Input
        </Button>
      </Tooltip>

      <ConstantModal thisIsTemplateCalc={true} />

      <ConstantTable />

      <Tooltip title="Add a new step to the list">
        <Button onClick={() => stepModalOpen(null)}>
          <PlusCircleOutlined />
          Calculation Step
        </Button>
      </Tooltip>

      <StepsModal thisIsTemplateCalc={true} />

      <StepsTable />
    </Modal>
  );
};

ModalCreateCalc.propTypes = {
  modalTempCalcVis: PropTypes.bool,
  createNewModalClose: PropTypes.func.isRequired,
  stepModalOpen: PropTypes.func.isRequired,
  constantModalOpen: PropTypes.func.isRequired,
  saveCalculation: PropTypes.func.isRequired,
  outputModalOpen: PropTypes.func.isRequired,
  loadAllTypesDetailed: PropTypes.func.isRequired,
  newCalculation: PropTypes.object,
};

const mapStateToProps = (state) => ({
  modalTempCalcVis: state.calcs.createNewModalVis,
  newCalculation: state.calcs.newCalculation,
});

export default connect(mapStateToProps, {
  createNewModalClose,
  stepModalOpen,
  constantModalOpen,
  saveCalculation,
  outputModalOpen,
  loadAllTypesDetailed,
})(ModalCreateCalc);
