import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

// andt Components
import { PageHeader, Button, Input, Tooltip } from "antd";
import { PlusCircleOutlined, CloudDownloadOutlined } from "@ant-design/icons";

// external components
import SitesTable from "./sitesTable";

// external funtions
import { clearSitesState, loadAllSites } from "../../../actions/sites";
import { clearClientState, loadAllClients } from "../../../actions/clients";

const CSVHeaders = [
  { label: "Site Name", key: "name" },
  { label: "Client", key: "client_name" },
  { label: "Legacy", key: "is_legacy" },
  { label: "Legacy ID", key: "legacy_id" },
  { label: "City", key: "address.city" },
  { label: "Province", key: "address.province" },
  { label: "Country", key: "address.country" },
  { label: "Longitude", key: "address.longitude" },
  { label: "Latitude", key: "address.latitude" },
  { label: "Contact Person", key: "site_contact.name" },
];

// actual component
const Index = ({
  permissions,
  isAdmin,
  clearSitesState,
  loadAllSites,
  pageWidth,
  siteData,
  userClientID,
  clearClientState,
  loadAllClients,
}) => {
  const [searchTerm, setSearchTerm] = useState(null);

  useEffect(() => {
    loadAllSites();
    loadAllClients();

    // clean up the effect by removing it completely
    return () => {
      clearSitesState();
      clearClientState();
    };
  }, [loadAllSites, clearSitesState, clearClientState, loadAllClients]);

  const onSearch = (text) => {
    setSearchTerm(text.toLowerCase());
  };

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        style={{
          paddingBottom: pageWidth < 795 && "0px",
          paddingTop: pageWidth < 795 && "0px",
        }}
        title="Sites and Branches"
        subTitle={pageWidth >= 795 && "Manage all your Sites and Branches"}
        extra={[
          <Input
            placeholder="Find something"
            allowClear
            onChange={(e) => onSearch(e.target.value)}
          />,
          <Link to={{ pathname: `/admin/sites/create` }}>
            <Button
              key="NewUserNotificaiton"
              type="primary"
              disabled={isAdmin ? false : !permissions?.write}
              icon={<PlusCircleOutlined />}
            >
              {pageWidth >= 795 && "New Site"}
            </Button>
          </Link>,
          ...(isAdmin
            ? [
                <CSVLink
                  data={
                    isAdmin === false
                      ? siteData !== null
                        ? siteData?.filter(
                            (item) => item?.client_id === userClientID
                          )
                        : []
                      : siteData ?? []
                  }
                  headers={CSVHeaders}
                  filename={"SiteList.csv"}
                  className="btn btn-primary"
                  target="_blank"
                >
                  <Tooltip title={pageWidth >= 795 && "Download site list"}>
                    <Button
                      style={{ backgroundColor: "green", color: "white" }}
                      icon={<CloudDownloadOutlined />}
                    ></Button>
                  </Tooltip>
                </CSVLink>,
              ]
            : []),
        ]}
      ></PageHeader>

      <SitesTable
        pageWidth={pageWidth}
        permissions={permissions}
        searchTerm={searchTerm}
      />
    </Fragment>
  );
};

Index.propTypes = {
  permissions: PropTypes.object,
  isAdmin: PropTypes.bool,
  clearSitesState: PropTypes.func.isRequired,
  loadAllSites: PropTypes.func.isRequired,
  pageWidth: PropTypes.number,
  siteData: PropTypes.array,
  userClientID: PropTypes.string,
  clearClientState: PropTypes.func.isRequired,
  loadAllClients: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  siteData: state.sites.data,
  userClientID: state.auth?.user?.client_id,
  isAdmin: state.auth.isAdmin,
  pageWidth: state.misc?.width,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "Client Site Management"
  )[0],
});

export default connect(mapStateToProps, {
  clearSitesState,
  loadAllSites,
  clearClientState,
  loadAllClients,
})(Index);
