import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

// andt Components
import { PageHeader, Button, Tabs, Spin } from "antd";
import {
  SaveOutlined,
  ProfileOutlined,
  LockOutlined,
  GroupOutlined,
  SolutionOutlined,
  BellOutlined,
  LineChartOutlined,
  PhoneFilled,
} from "@ant-design/icons";

import { AiOutlineTeam } from "react-icons/ai";

// external components
import TabBasic from "./tabs/Basic";
import TabSiteContact from "./tabs/SiteContact";
import TabSiteAddress from "./tabs/Address";
import TabFloors from "./tabs/Floors";
import TabSiteMutedAlarms from "./tabs/SiteMutedAlarms";
import TabUsers from "./tabs/User";
import TabTrendsDashboard from "./tabs/TrendsDashboard";
import TabEmergencyContacts from "./tabs/EmergencyContacts";

// external functions
import {
  GetSpecificSiteDetails,
  clearSitesState,
  UpdateSpecificSite,
} from "../../../actions/sites";
import { loadAllClients, clearClientState } from "../../../actions/clients";

const { TabPane } = Tabs;

// actual component
const Index = ({
  permissions,
  isAdmin,
  editData,
  match,
  GetSpecificSiteDetails,
  clearSitesState,
  loadAllClients,
  clearClientState,
  UpdateSpecificSite,
  pageWidth,
}) => {
  const history = useHistory();
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);

  useEffect(() => {
    if (match.params.siteID) GetSpecificSiteDetails(match.params.siteID);

    if (isAdmin) loadAllClients();

    // clean up the effect by removing it completely
    return () => {
      clearSitesState();
      clearClientState();
    };
  }, [
    clearSitesState,
    GetSpecificSiteDetails,
    match.params,
    loadAllClients,
    clearClientState,
    isAdmin,
  ]);

  const handleSave = async () => {
    // show loading state on the save button
    setSaveButtonLoading(true);
    // // update the user
    await UpdateSpecificSite(editData._id, editData);
    setTimeout(() => {
      // clear the loading state on the save button
      setSaveButtonLoading(false);
      // redirect the user back
      history.push(`/admin/sites`);
    }, 2000);
  };

  return editData === null ? (
    <Spin
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "50%",
        transform: "translate(-50%, -50%)",
      }}
      size="large"
    />
  ) : (
    <Fragment>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={pageWidth > 795 ? `Viewing ${editData.name}` : "Viewing site"}
        subTitle={
          pageWidth > 795 &&
          "Make sure to save changes before you leave this page"
        }
        extra={[
          <Button
            key="NewUser"
            type="primary"
            disabled={isAdmin ? false : !permissions?.write}
            icon={<SaveOutlined />}
            onClick={handleSave}
            loading={saveButtonLoading}
          >
            {pageWidth > 795 && "Save Changes"}
          </Button>,
        ]}
      ></PageHeader>

      <Tabs
        defaultActiveKey="Basic"
        style={{ marginTop: "10px" }}
        tabPosition={pageWidth > 795 ? "left" : "top"}
        size={pageWidth > 795 ? "large" : "small"}
      >
        <TabPane
          tab={
            <span>
              <ProfileOutlined />
              Basic Information
            </span>
          }
          key="Basic"
        >
          <TabBasic
            isAdmin={isAdmin}
            permissions={permissions}
            pageWidth={pageWidth}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <SolutionOutlined />
              Site Contact
            </span>
          }
          key="SiteContact"
        >
          <TabSiteContact
            isAdmin={isAdmin}
            permissions={permissions}
            pageWidth={pageWidth}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <LockOutlined />
              Address
            </span>
          }
          key="Address"
        >
          <TabSiteAddress
            isAdmin={isAdmin}
            permissions={permissions}
            pageWidth={pageWidth}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <GroupOutlined />
              Floors
            </span>
          }
          key="Floors"
        >
          <TabFloors
            isAdmin={isAdmin}
            permissions={permissions}
            pageWidth={pageWidth}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <AiOutlineTeam style={{ marginRight: "10px" }} />
              Users
            </span>
          }
          key="Users"
        >
          <TabUsers
            isAdmin={isAdmin}
            permissions={permissions}
            pageWidth={pageWidth}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <LineChartOutlined />
              Dashboards
            </span>
          }
          key="Trends"
        >
          <TabTrendsDashboard
            isAdmin={isAdmin}
            permissions={permissions}
            pageWidth={pageWidth}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <PhoneFilled />
              Emergency Contacts
            </span>
          }
          key="EMSContacts"
        >
          <TabEmergencyContacts
            isAdmin={isAdmin}
            permissions={permissions}
            pageWidth={pageWidth}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <BellOutlined />
              Site Muted Alarms
            </span>
          }
          key="SiteMutedAlarms"
        >
          <TabSiteMutedAlarms
            isAdmin={isAdmin}
            permissions={permissions}
            pageWidth={pageWidth}
          />
        </TabPane>
      </Tabs>
    </Fragment>
  );
};

Index.propTypes = {
  permissions: PropTypes.object,
  editData: PropTypes.object,
  isAdmin: PropTypes.bool,
  GetSpecificSiteDetails: PropTypes.func.isRequired,
  clearSitesState: PropTypes.func.isRequired,
  loadAllClients: PropTypes.func.isRequired,
  clearClientState: PropTypes.func.isRequired,
  UpdateSpecificSite: PropTypes.func.isRequired,
  pageWidth: PropTypes.number,
};

const mapStateToProps = (state) => ({
  editData: state.sites.editData,
  isAdmin: state.auth.isAdmin,
  pageWidth: state.misc?.width,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "Client Site Management"
  )[0],
});

export default connect(mapStateToProps, {
  GetSpecificSiteDetails,
  clearSitesState,
  loadAllClients,
  clearClientState,
  UpdateSpecificSite,
})(Index);
