import React, { Fragment } from "react";

// external comopnet
import TableComponent from "./Components/TableComponent";

// antd comopnet
import { Typography } from "antd";

// actual componet
const TabStatsType = ({ tabSelected }) => {
  return (
    <Fragment>
      <Typography>The items here will be avaible on the stats tab</Typography>

      <TableComponent tabSelected={tabSelected} />
    </Fragment>
  );
};

export default TabStatsType;
