import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd component
import { Button, Input, Space, Table, Tooltip } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// external functions
import {
  AddNewSiteToUserTableLoaded,
  AddNewSiteToUser,
  ModalSitesAddClose,
  EditSpecificUser,
} from "../../../../actions/users";

// actual component
const TabClientSpecific = ({
  avaiableSites,
  userData,
  modalVis,
  addSiteTableLoaded,
  AddNewSiteToUserTableLoaded,
  AddNewSiteToUser,
  currentSites,
  newSite,
  ModalSitesAddClose,
  EditSpecificUser,
}) => {
  const [tableData, setTableData] = useState([]);
  const [serchTerm, setSerchTerm] = useState(null);

  useEffect(() => {
    if (modalVis) {
      //   if (tabletLoaded.current === false) {
      if (addSiteTableLoaded === false) {
        let tab = [];

        if (avaiableSites !== null) {
          avaiableSites.map((current) => {
            let number = 0;

            if (current.client_id === userData.client_id) {
              currentSites?.map((item) => {
                if (item.name === current.name) {
                  number++;
                }

                return true;
              });

              if (number === 0) {
                tab.push({ ...current, key: current._id });
              }
            }

            return true;
          });

          setTableData(tab);
        }

        // tabletLoaded.current = true;
        AddNewSiteToUserTableLoaded(true);
      }
    }
  }, [
    modalVis,
    avaiableSites,
    userData.client_id,
    currentSites,
    AddNewSiteToUserTableLoaded,
    addSiteTableLoaded,
  ]);

  const handelClose = (lastData) => {
    let user = { ...userData };
    let userSites = newSite;

    if (newSite.length > 0) {
      user.sites.map((item) => userSites.push({ ...item }));
      user.sites = userSites;
    }

    if (lastData !== null) user.sites.push(lastData);

    EditSpecificUser(user);
    setSerchTerm(null);
    ModalSitesAddClose();
  };

  const handleAdd = (record) => {
    let oldData = [...tableData];
    let newSites = [...newSite];

    delete record.key;
    newSites.push(record);

    AddNewSiteToUser(newSites);

    if (oldData.length === 1) {
      handelClose(record);
    } else {
      setTableData(oldData.filter((item) => item.name !== record.name));
    }
  };

  const columns = [
    {
      title: "Site",
      key: "name",
      dataIndex: "name",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "",
      width: "70px",
      dataIndex: "operation",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Add this site">
            <Button
              shape="circle"
              type="link"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={() => handleAdd(record)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handelSearch = (searchText) => {
    if (searchText !== "" || searchText !== undefined)
      setSerchTerm(searchText.toLowerCase());
    else setSerchTerm(null);
  };

  return (
    <Fragment>
      <Space>
        <p>Click to add sites to the user</p>
        <Input
          style={{
            // marginBottom: 20,
            padding: 0,
            width: 200,
            top: "75px",
            position: "absolute",
            right: "24px",
          }}
          size="small"
          onChange={(serachText) => handelSearch(serachText.target.value)}
          placeholder="Search Something"
          allowClear
        ></Input>
      </Space>

      <Table
        columns={columns}
        dataSource={
          serchTerm !== null
            ? tableData &&
              tableData.filter((item) =>
                item.name.toLowerCase().includes(serchTerm)
              )
            : tableData
        }
        pagination={false}
        size={"small"}
        bordered
        scroll={{ y: 240 }}
      ></Table>
    </Fragment>
  );
};

TabClientSpecific.propTypes = {
  avaiableSites: PropTypes.array,
  userData: PropTypes.object,
  modalVis: PropTypes.bool,
  addSiteTableLoaded: PropTypes.bool,
  AddNewSiteToUserTableLoaded: PropTypes.func.isRequired,
  AddNewSiteToUser: PropTypes.func.isRequired,
  ModalSitesAddClose: PropTypes.func.isRequired,
  EditSpecificUser: PropTypes.func.isRequired,
  currentSites: PropTypes.array,
  newSite: PropTypes.array,
};

const MapStateToProps = (state) => ({
  userData: state.users?.editData,
  currentSites: state.users?.editData?.sites,
  avaiableSites: state.sites.data,
  modalVis: state.users.modalAddSitesVis,
  addSiteTableLoaded: state.users.addSiteTableLoaded,
  newSite: state.users.newSite ?? [],
});

export default connect(MapStateToProps, {
  AddNewSiteToUserTableLoaded,
  AddNewSiteToUser,
  ModalSitesAddClose,
  EditSpecificUser,
})(TabClientSpecific);
