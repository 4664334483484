import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// antd comonet
import { Button, Card, Divider, List, Space, Tooltip } from "antd";
import {
  PlusCircleOutlined,
  CloseCircleOutlined,
  LineChartOutlined,
  EditFilled,
} from "@ant-design/icons";
import { EditViewSite, ModalAddDashboardOpen } from "../../../../actions/sites";

// externt functions
import ModalAddDashboard from "../create/modals/ModalAddDashboard";

// actual componet
const TrendsDashboard = ({
  pageWidth,
  isAdmin,
  permissions,
  editData,
  EditViewSite,
  ModalAddDashboardOpen,
}) => {
  const handleDeleteDashboard = (record) => {
    if (record) {
      let thisSitesData = { ...editData };

      thisSitesData.trendsDashBoard = thisSitesData.trendsDashBoard.filter(
        (dashboard) => dashboard.key !== record.key
      );
      EditViewSite(thisSitesData);
    }
  };

  return (
    <Card
      title={
        <span>
          <LineChartOutlined /> Dashboards
        </span>
      }
      bordered={false}
    >
      <ModalAddDashboard />

      <div>
        <p style={{ fontWeight: "bold" }}>
          {permissions?.update | isAdmin ? (
            <span>
              <Button
                type="text"
                style={{ color: "blue", float: "right" }}
                icon={<PlusCircleOutlined />}
                onClick={() => ModalAddDashboardOpen()}
              >
                Add Dashboard
              </Button>
            </span>
          ) : (
            <></>
          )}
        </p>
      </div>
      <div
        id="scrollableDiv"
        style={{
          height: pageWidth >= 768 ? "calc(100vh - 31em)" : "100px",
          width: "100%",
          overflow: "auto",
          padding: "0 16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
        }}
      >
        <InfiniteScroll
          dataLength={
            editData?.trendsDashBoard?.length === undefined
              ? 0
              : editData?.trendsDashBoard?.length
          }
          hasMore={false}
          loader={<h4>Loading...</h4>}
          endMessage={
            editData?.trendsDashBoard?.length > 0 && (
              <Divider plain>Done!! add more Dashboards</Divider>
            )
          }
          scrollableTarget="scrollableDiv"
        >
          <List
            size="small"
            dataSource={editData?.trendsDashBoard}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <p style={{ width: "100px", margin: 0, padding: 0 }}>
                  {" "}
                  {item.name}
                </p>
                <p style={{ width: "700px", margin: 0, padding: 0 }}>
                  {item.url}
                </p>
                <Space size="small">
                  <Tooltip title="Click to remove this dashboard">
                    <Button
                      type="link"
                      onClick={() => handleDeleteDashboard(item)}
                      icon={<CloseCircleOutlined style={{ color: "red" }} />}
                    ></Button>
                  </Tooltip>
                  <Button
                    type="link"
                    onClick={() => ModalAddDashboardOpen(item)}
                    icon={<EditFilled style={{ color: "black" }} />}
                  ></Button>
                </Space>
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    </Card>
  );
};

TrendsDashboard.propTypes = {
  editData: PropTypes.object,
  EditViewSite: PropTypes.func.isRequired,
  ModalAddDashboardOpen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  editData: state.sites.editData,
});

export default connect(mapStateToProps, {
  EditViewSite,
  ModalAddDashboardOpen,
})(TrendsDashboard);
