import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Antd components
import { Table, Space, Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

// External Functions
import {
  constantDelete,
  constantModalOpen,
} from "../../../../../actions/staffTools/calculations";

// Actual Componet
const CreateNewConstTable = ({
  newCalc,
  constantDelete,
  constantModalOpen,
}) => {
  const handleDelete = (record) => {
    constantDelete(record);
  };

  const handleEdit = (record) => {
    constantModalOpen(record);
  };

  const tableColums = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Allow Multiples",
      dataIndex: "allowMultiples",
      key: "allowMultiples",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Select for list only",
      dataIndex: "listSelect",
      key: "listSelect",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Locked Type",
      dataIndex: "types",
      key: "types",
      // render: (text) =>
      //   text && text.length > 0 && text.map((item) => item + ","),
    },
    {
      title: "Type Variable",
      dataIndex: "typeVariable",
      key: "typeVariable",
    },
    {
      title: "",
      key: "action",
      width: "70px",
      render: (_, record) => (
        <Space size="small">
          <Popconfirm
            title={"Sure you want to delete " + record.name + " ?"}
            onConfirm={() => {
              handleDelete(record);
            }}
          >
            <a href="#!">
              <DeleteOutlined style={{ color: "red" }} />
            </a>
          </Popconfirm>
          <a href="#!" onClick={() => handleEdit(record)}>
            <EditOutlined />
          </a>
        </Space>
      ),
    },
  ];

  return (
    <Table
      style={{ marginTop: "10px", marginBottom: "10px" }}
      size="small"
      columns={tableColums}
      scroll={{ y: 150 }}
      dataSource={newCalc && newCalc.constant}
    ></Table>
  );
};

CreateNewConstTable.propTypes = {
  newCalc: PropTypes.object,
  constantDelete: PropTypes.func.isRequired,
  constantModalOpen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  newCalc: state.calcs.newCalculation,
});

export default connect(mapStateToProps, { constantDelete, constantModalOpen })(
  CreateNewConstTable
);
