import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";

// andt components
import { Modal, Form, Input, message } from "antd";

// external functions
import {
  ModalAddContactClose,
  NewSiteUpdate,
  EditViewSite,
} from "../../../../../actions/sites";

// actual component
const ModalAddContact = ({
  modalVis,
  ModalAddContactClose,
  pageWidth,
  modalData,
  editData,
  EditViewSite,
}) => {
  const inputRef = useRef();
  const formLoaded = useRef(false);
  const [formDetails] = Form.useForm();

  const handleOk = () => {
    const id = uuid();
    let data = formDetails.getFieldsValue();

    data.name = data.name.trim().toLowerCase();
    data.surname = data.surname.trim().toLowerCase();
    data.email = data.email.trim().toLowerCase();
    data.function = data.function.trim().toLowerCase();

    data.name = data.name[0].toUpperCase() + data.name.slice(1);
    data.surname = data.surname[0].toUpperCase() + data.surname.slice(1);

    let siteData = { ...editData };

    if (modalData === null) {
      data.phone.phone = parseInt(data.phone.phone);

      data.phone = "+" + data.phone.code + data.phone.phone;
      if (siteData?.emergencyContact === undefined)
        siteData.emergencyContact = [];

      let duplicateIndex = siteData?.emergencyContact.findIndex(
        (trend) =>
          trend.name === data.name &&
          trend.surname === data.surname &&
          trend.email === data.email
      );

      if (duplicateIndex === -1) {
        EditViewSite({ ...siteData, emergencyContact: [] });

        siteData.emergencyContact.push({ ...data, key: id });
      } else {
        return message.error("Duplicate entry try again");
      }
    } else {
      let index = siteData?.emergencyContact.findIndex(
        (trend) => trend.key === modalData.key
      );

      if (index !== -1) {
        EditViewSite({ ...siteData, emergencyContact: [] });
        siteData.emergencyContact[index] = { ...data, key: modalData.key };
      }
    }
    EditViewSite({ ...siteData });

    ModalAddContactClose();
  };

  const handleCancel = () => {
    ModalAddContactClose();
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus({
        cursor: "start",
      });
    }, 100);
  }, [modalVis]);

  if (modalData !== null && modalVis === true && formLoaded.current === false) {
    formLoaded.current = true;
    formDetails.setFieldsValue({ ...modalData });
  }

  return (
    <Modal
      title={modalData === null ? "Add new Contact" : "Editing Contact"}
      onCancel={handleCancel}
      okText={modalData === null ? "Add" : "Edit"}
      afterClose={() => {
        formLoaded.current = false;
        formDetails.resetFields();
      }}
      onOk={() => formDetails.validateFields().then(() => handleOk())}
      visible={modalVis}
    >
      <ConfigProvider locale={en}>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 13 }}
          size="small"
          form={formDetails}
        >
          <Form.Item
            label={<span style={{ color: "black" }}>Name</span>}
            name={"name"}
            style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
            rules={[
              {
                required: true,
                message: "Please input a valid name!",
              },
            ]}
          >
            <Input ref={inputRef} />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: "black" }}>Surname</span>}
            name={"surname"}
            style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
            rules={[
              {
                required: true,
                message: "Please enter a valid surname",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: "black" }}>Email</span>}
            name={"email"}
            style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input />
          </Form.Item>
          {modalData === null ? (
            <Form.Item
              label={<span style={{ color: "black" }}>Phone</span>}
              name={"phone"}
              style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
              initialValue={{
                short: "za",
              }}
              rules={[
                {
                  message: "Phone number cannot be blank",
                  validator: (_, value) => {
                    if (value.phone) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Phone number cannot be blank");
                    }
                  },
                },
              ]}
            >
              <CountryPhoneInput />
            </Form.Item>
          ) : (
            <Form.Item
              label={<span style={{ color: "black" }}>Phone</span>}
              name={"phone"}
              style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a valid number",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            label={<span style={{ color: "black" }}>Function</span>}
            name={"function"}
            style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
            rules={[
              {
                required: true,
                message: "Please enter a valid function",
              },
            ]}
            tooltip="This could be Level 2 responce,UPS site specialist, maintenace technician"
          >
            <Input />
          </Form.Item>
        </Form>
      </ConfigProvider>
    </Modal>
  );
};

ModalAddContact.propTypes = {
  modalVis: PropTypes.bool,
  modalData: PropTypes.object,
  ModalAddContactClose: PropTypes.func.isRequired,
  EditViewSite: PropTypes.func.isRequired,
  editData: PropTypes.object,
  pageWidth: PropTypes.number,
};

const mapStateToProps = (state) => ({
  modalVis: state.sites.modalAddContVis,
  modalData: state.sites.modalAddContData,
  editData: state.sites.editData,
  pageWidth: state.misc?.width,
});

export default connect(mapStateToProps, {
  ModalAddContactClose,
  NewSiteUpdate,
  EditViewSite,
})(ModalAddContact);
