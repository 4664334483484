import React, { Fragment, lazy, Suspense, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd comonet
import { Button, PageHeader, Spin } from "antd";
import { PlusCircleOutlined, ImportOutlined } from "@ant-design/icons";

// external functions
import {
  clearStateTemplates,
  loadAllTemplates,
  ModalNewTemplateOpen,
  ModalOpenImportTemplate,
} from "../../../../actions/staffTools/templates";
import {
  clearStateTypes,
  loadAllTypes,
  loadAllTypesDetailed,
} from "../../../../actions/staffTools/types";
import {
  clearStateManufactures,
  loadAllManufactures,
} from "../../../../actions/staffTools/manufacture";
import ModalAddBatch from "./Modal/ModalAddBatch";

// external components
const TemplatesTable = lazy(() => import("./table"));
const ModalCreateNew = lazy(() => import("./Modal/ModalCreateNew"));
const ImportTemplateModal = lazy(() => import("./Modal/ImportTemplateModal"));

// actual componet
const UCTemplates = ({
  pageWidth,
  isAdmin,
  ModalOpenImportTemplate,
  permissions,
  loadAllTemplates,
  clearStateTemplates,
  clearStateTypes,
  loadAllTypes,
  loadAllManufactures,
  clearStateManufactures,
  ModalNewTemplateOpen,
  loadAllTypesDetailed,
}) => {
  useEffect(() => {
    loadAllTemplates();
    loadAllTypes();
    loadAllManufactures();
    loadAllTypesDetailed();

    return () => {
      clearStateTemplates();
      clearStateTypes();
      clearStateManufactures();
    };
  }, [
    loadAllTemplates,
    clearStateTemplates,
    clearStateTypes,
    loadAllTypes,
    loadAllManufactures,
    clearStateManufactures,
    loadAllTypesDetailed,
  ]);

  return (
    <Suspense
      fallback={
        <Spin
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            width: "50%",
            transform: "translate(-50%, -50%)",
          }}
          size="large"
        />
      }
    >
      <Fragment>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="UC Templates"
          style={{
            paddingBottom: pageWidth < 795 && "0px",
            paddingTop: pageWidth < 795 && "0px",
          }}
          subTitle={
            pageWidth > 795 &&
            "Templates combine manufactures with types, tells the controller how to read devices"
          }
          extra={[
            <Button
              onClick={() => ModalOpenImportTemplate()}
              icon={<ImportOutlined />}
              disabled={isAdmin ? false : !permissions?.write}
              key={"Import"}
            >
              {pageWidth > 1240 && "Import"}
            </Button>,
            <Button
              key="newItem"
              type="primary"
              disabled={isAdmin ? false : !permissions?.write}
              icon={<PlusCircleOutlined />}
              onClick={() => ModalNewTemplateOpen(null)}
            >
              {pageWidth > 795 && "New"}
            </Button>,
          ]}
        ></PageHeader>

        <ImportTemplateModal />

        <ModalAddBatch
          pageWidth={pageWidth}
          isAdmin={isAdmin}
          permissions={permissions}
        />

        <ModalCreateNew
          pageWidth={pageWidth}
          isAdmin={isAdmin}
          permissions={permissions}
        />

        <TemplatesTable
          pageWidth={pageWidth}
          isAdmin={isAdmin}
          permissions={permissions}
        />
      </Fragment>
    </Suspense>
  );
};

UCTemplates.propTypes = {
  isAdmin: PropTypes.bool,
  pageWidth: PropTypes.number,
  permissions: PropTypes.object,
  loadAllTemplates: PropTypes.func.isRequired,
  clearStateTemplates: PropTypes.func.isRequired,
  clearStateTypes: PropTypes.func.isRequired,
  loadAllTypes: PropTypes.func.isRequired,
  loadAllManufactures: PropTypes.func.isRequired,
  clearStateManufactures: PropTypes.func.isRequired,
  ModalNewTemplateOpen: PropTypes.func.isRequired,
  ModalOpenImportTemplate: PropTypes.func.isRequired,
  loadAllTypesDetailed: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  pageWidth: state.misc?.width,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "UC Templates"
  )[0],
});

export default React.memo(
  connect(mapStateToProps, {
    loadAllTemplates,
    clearStateTemplates,
    clearStateTypes,
    loadAllTypes,
    loadAllManufactures,
    ModalNewTemplateOpen,
    clearStateManufactures,
    ModalOpenImportTemplate,
    loadAllTypesDetailed,
  })(UCTemplates)
);
