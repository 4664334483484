import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// andt componet
import { Button, PageHeader, Tabs } from "antd";
import { PlusCircleOutlined, ImportOutlined } from "@ant-design/icons";

// external compon
import TabDeviceType from "./TabDeviceType";
import TabStatsType from "./TabStatsType";
import TabVirtualDevices from "./TabVirtualDevices";

import ModalAddEdit from "./Components/Modals/ModalAddEdit";
import ModalImportCalc from "./Components/Modals/ModalImportCalc";

// external fucntions
import {
  ClearAllCalcs,
  LoadAllCalcs,
  ModalOpenImportCalculation,
  createNewModalOpen,
} from "../../../../actions/staffTools/calculations";

const { TabPane } = Tabs;

// actual compoent
const Calculations = ({
  ClearAllCalcs,
  LoadAllCalcs,
  isAdmin,
  permissions,
  createNewModalOpen,
  ModalOpenImportCalculation,
  pageWidth,
}) => {
  const [tabKey, setTabKey] = useState("1");

  useEffect(() => {
    LoadAllCalcs();

    return () => {
      ClearAllCalcs();
    };
  }, [LoadAllCalcs, ClearAllCalcs]);

  const onTabChange = (e) => {
    setTabKey(e);
  };

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="UC Calculations"
        style={{
          paddingBottom: pageWidth < 795 && "0px",
          paddingTop: pageWidth < 795 && "0px",
        }}
        subTitle={
          pageWidth > 795 &&
          "The following is a list of calculations you can use this to associate to a template or use in the main config"
        }
        extra={[
          <Button
            onClick={() => ModalOpenImportCalculation()}
            icon={<ImportOutlined />}
            disabled={isAdmin ? false : !permissions?.write}
            key={"Import"}
          >
            {pageWidth > 1240 && "Import"}
          </Button>,
          <Button
            key="newItem"
            type="primary"
            disabled={isAdmin ? false : !permissions?.write}
            icon={<PlusCircleOutlined />}
            onClick={() => createNewModalOpen(null)}
          >
            {pageWidth > 795 && "New"}
          </Button>,
        ]}
      ></PageHeader>

      <ModalAddEdit tabSelected={tabKey} />

      <ModalImportCalc />

      <Tabs defaultActiveKey="1" onChange={onTabChange}>
        <TabPane tab="Device" key="1">
          <TabDeviceType tabSelected={tabKey} />
        </TabPane>
        <TabPane tab="Stats" key="2">
          <TabStatsType tabSelected={tabKey} />
        </TabPane>
        <TabPane tab="Virtual Devices" key="3">
          <TabVirtualDevices tabSelected={tabKey} />
        </TabPane>
      </Tabs>
    </Fragment>
  );
};

Calculations.propTypes = {
  ClearAllCalcs: PropTypes.func.isRequired,
  LoadAllCalcs: PropTypes.func.isRequired,
  createNewModalOpen: PropTypes.func.isRequired,
  ModalOpenImportCalculation: PropTypes.func.isRequired,
  updateLoading: PropTypes.bool,
  pageWidth: PropTypes.number,
};

const mapStateToProps = (state) => ({
  updateLoading: state.calcs.updateLoading,
  isAdmin: state.auth.isAdmin,
  pageWidth: state.misc?.width,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "UC Templates"
  )[0],
});

export default React.memo(
  connect(mapStateToProps, {
    ClearAllCalcs,
    LoadAllCalcs,
    createNewModalOpen,
    ModalOpenImportCalculation,
  })(Calculations)
);
