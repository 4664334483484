import React, { Fragment, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Antd componet
import { Modal, Form, Input, Checkbox, Select, message } from "antd";

// external functions
import {
  constantModalClose,
  constantEdit,
} from "../../../../../../actions/staffTools/calculations";

// actual Component
const NewConstModal = ({
  modalVis,
  constantModalClose,
  deviceTypesDetailed,
  newCalculation,
  modalData,
  constantEdit,
}) => {
  const [constForm] = Form.useForm();
  const modalLoaded = useRef(false);
  const [formData, setformData] = useState({});
  const [listSelectableCK, setListSelectableCK] = useState(false);
  const [allowMultiplesCK, setAllowMultiplesCK] = useState(false);
  const [typeVars, setTypeVars] = useState(null);

  const closingProcedure = () => {
    setformData({});
    setTypeVars(null);
    setListSelectableCK(false);
    setAllowMultiplesCK(false);
    constForm.resetFields();
    modalLoaded.current = false;
    constantModalClose();
  };

  const handleOK = () => {
    const key = uuid();
    let newCalcConst = newCalculation?.constant;
    let newFormData = constForm.getFieldsValue();

    newFormData.name = newFormData.name.trim();

    newFormData.allowMultiples = allowMultiplesCK;
    newFormData.listSelect = listSelectableCK;

    if (newCalcConst === undefined) newCalcConst = [];

    constantEdit([]);

    // is it a new oen or an edit
    if (modalData === null) {
      let matchIndex = newCalcConst.findIndex(
        (matchCalc) => matchCalc.name === newFormData.name
      );

      if (matchIndex !== -1) {
        constantEdit(newCalcConst);
        return message.error("This name already exists, no duplicated allowed");
      }
      newCalcConst.push({ ...newFormData, key });
    } else {
      let index = newCalcConst.findIndex((cons) => cons.key === modalData.key);

      if (newFormData.listSelect === false) {
        newFormData.allowMultiples = false;
        delete newFormData.allowMultiples;

        if (index !== -1) newCalcConst[index] = { ...newFormData };
      } else {
        if (index !== -1)
          newCalcConst[index] = { ...modalData, ...newFormData };
      }
    }

    setTimeout(() => {
      constantEdit(newCalcConst);
      closingProcedure();
    }, 100);
  };

  const handleCancel = () => {
    closingProcedure();
  };

  const handleTypeSelect = (value) => {
    if (value !== "" && value !== undefined) {
      const typeSelected = deviceTypesDetailed.find(
        (type) => type.device_type_name === value
      );

      if (typeSelected) {
        setTypeVars(typeSelected.vairables);
      }
    } else {
      setTypeVars([]);
    }

    constForm.setFieldsValue({ typeVariable: undefined });
  };

  // Device Types select
  const DeviceTypeList =
    deviceTypesDetailed !== null
      ? deviceTypesDetailed.map((device, index) => (
          <Select.Option value={device.device_type_name} key={index}>
            {device.device_type_name}
          </Select.Option>
        ))
      : null;

  // Device Types select
  const DeviceVarList = typeVars
    ? typeVars.map((device, index) => (
        <Select.Option value={device.variable} key={index}>
          {device.variable}
        </Select.Option>
      ))
    : null;

  if (
    modalVis === true &&
    modalData !== null &&
    modalLoaded.current === false
  ) {
    modalLoaded.current = true;

    constForm.setFieldsValue({
      name: modalData.name,
      listSelect: modalData.listSelect,
    });
    setListSelectableCK(modalData.listSelect);
    constForm.setFieldsValue({
      types: modalData.types,
      allowMultiples: modalData.allowMultiples,
    });
    handleTypeSelect(modalData.types);
    constForm.setFieldsValue({ typeVariable: modalData.typeVariable });
    setAllowMultiplesCK(modalData.allowMultiples);
  }

  return (
    <Modal
      title={modalData !== null ? "Edit constant" : "Add a constant"}
      onCancel={handleCancel}
      onOk={() =>
        constForm
          .validateFields()
          .then(() => handleOK())
          .catch()
      }
      afterClose={() => {
        setTypeVars(null);
        constForm.resetFields();
        setformData({});
      }}
      visible={modalVis}
    >
      <Form
        size="small"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 10 }}
        form={constForm}
      >
        {modalData && modalData.thisIsVitualCalc === true ? (
          <Form.Item
            label="Variable of type"
            tooltip="This will be the variable of the type, it is what will be extracted from the type"
            name="typeVariable"
          >
            <Select
              showSearch
              allowClear
              onChange={(value) => {
                setformData({
                  ...formData,
                  name: value,
                  typeVariable: value,
                });
                constForm.setFieldsValue({ name: value });
              }}
            >
              {DeviceVarList}
            </Select>
          </Form.Item>
        ) : (
          <Fragment>
            <Form.Item
              label="Name"
              name="name"
              style={{ marginBottom: "5px" }}
              rules={[
                {
                  required: true,
                  message: `Can't be blank`,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="List selectable"
              tooltip="The user must be only able to select from a list of devices and not input unique numbers/data"
              name="listSelect"
              style={{ marginBottom: "5px" }}
            >
              <Checkbox
                checked={listSelectableCK}
                onChange={(e) =>
                  e.target.checked
                    ? setListSelectableCK(true)
                    : setListSelectableCK(false)
                }
              />
            </Form.Item>
            {listSelectableCK && (
              <Fragment>
                <Form.Item
                  label="Allow multiples"
                  tooltip="Allow the user to be able to select more than one device for this constant"
                  name="allowMultiples"
                  style={{ marginBottom: "5px" }}
                >
                  <Checkbox
                    checked={allowMultiplesCK}
                    onChange={(e) =>
                      e.target.checked
                        ? setAllowMultiplesCK(true)
                        : setAllowMultiplesCK(false)
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Lock to type"
                  tooltip="This will limit the user to only be able to select the type of device for this type"
                  name="types"
                >
                  {newCalculation && newCalculation.inOutSameType ? (
                    <Input disabled />
                  ) : (
                    <Select showSearch allowClear onChange={handleTypeSelect}>
                      {DeviceTypeList}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item
                  label="Variable of type"
                  tooltip="This will be the variable of the type, it is what will be extracted from the type"
                  name="typeVariable"
                >
                  <Select showSearch allowClear>
                    {DeviceVarList}
                  </Select>
                </Form.Item>
              </Fragment>
            )}
          </Fragment>
        )}
      </Form>
    </Modal>
  );
};

NewConstModal.propTypes = {
  modalVis: PropTypes.bool,
  modalData: PropTypes.object,
  constantModalClose: PropTypes.func.isRequired,
  constantEdit: PropTypes.func.isRequired,
  deviceTypesDetailed: PropTypes.array,
  newCalculation: PropTypes.object,
};

const mapStateToProps = (state) => ({
  modalVis: state.calcs.constantModalVis,
  modalData: state.calcs.constantModalData,
  deviceTypesDetailed: state.types?.types_detailed,
  newCalculation: state.calcs.newCalculation,
});

export default connect(mapStateToProps, {
  constantModalClose,
  constantEdit,
})(NewConstModal);
