import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Antd components
import { Table, Space, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

// External Functions
import { outputDelete } from "../../../../../actions/staffTools/calculations";

// Actual Componet
const CreateNewOutputTable = ({ permissions, newCalc, outputDelete }) => {
  const handleDelete = (record) => {
    outputDelete(record);
  };

  const tableColums = [
    {
      title: "Type",
      dataIndex: "outputType",
      key: "outputType",
    },
    {
      title: "Variable",
      dataIndex: "outputVariable",
      key: "outputVariable",
    },
    {
      title: "Symbol",
      dataIndex: "outputSymbol",
      key: "outputSymbol",
    },
    {
      title: "",
      key: "action",
      width: "70px",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title={
              "Sure you want to delete " +
              record.outputType +
              ":" +
              record.outputVariable +
              " ?"
            }
            onConfirm={() => {
              handleDelete(record);
            }}
          >
            <a href="#!">
              <DeleteOutlined style={{ color: "red" }} />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      style={{ marginTop: "10px", marginBottom: "10px" }}
      size="small"
      columns={tableColums}
      scroll={{ y: 150 }}
      dataSource={newCalc && newCalc.outputs}
    ></Table>
  );
};

CreateNewOutputTable.propTypes = {
  newCalc: PropTypes.object,
  outputDelete: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  newCalc: state.calcs.newCalculation,
});

export default connect(mapStateToProps, { outputDelete })(CreateNewOutputTable);
