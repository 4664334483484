import React, { Fragment } from "react";
// import PropTypes from "prop-types";

// external componet
import TableComponent from "./Components/TableComponent";

// antd comopnet
import { Typography } from "antd";

// actual componet
const TabVirtualDevices = ({ tabSelected }) => {
  return (
    <Fragment>
      <Typography>
        These are virtual devices that can be added by the user, these templates
        make it easier for the user to only select the device they want.
      </Typography>

      <TableComponent tabSelected={tabSelected} />
    </Fragment>
  );
};

TabVirtualDevices.propTypes = {};

export default TabVirtualDevices;
