import React, { Fragment } from "react";

// external comopnet
import TableComponent from "./Components/TableComponent";

// antd comopnet
import { Typography } from "antd";

// actual componet
const TabDeviceType = ({ tabSelected }) => {
  return (
    <Fragment>
      <Typography>
        The items here will be viewed on the devices to read page
      </Typography>

      <TableComponent tabSelected={tabSelected} />
    </Fragment>
  );
};

export default TabDeviceType;
