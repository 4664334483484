import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd components
import { Card, Descriptions, Typography, Select, Switch } from "antd";
import { HighlightOutlined, ProfileOutlined } from "@ant-design/icons";

// external functions
import { EditViewSite } from "../../../../actions/sites";

const { Paragraph } = Typography;

// actual component
const Basic = ({
  editData,
  isAdmin,
  EditViewSite,
  clientsData,
  permissions,
  pageWidth,
}) => {
  const handleChange = (e) => {
    if (e) {
      const siteChange = { ...editData };

      if (e.section === "legacy_id") {
        if (Number(e.val)) siteChange[e.section] = Number(e.val);
      } else {
        siteChange[e.section] = e.val;
      }

      EditViewSite(siteChange);
    }
  };

  const handleSwitch = (type, val) => {
    const siteChange = { ...editData };

    switch (type) {
      case "is_visible":
        siteChange.is_visible = val;
        break;
      case "is_legacy":
        siteChange.is_legacy = val;
        break;
      default:
        break;
    }

    EditViewSite(siteChange);
  };

  return (
    <Card
      title={
        <span>
          <ProfileOutlined /> Basic Information
        </span>
      }
      bordered={false}
    >
      <Descriptions bordered>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold", width: "200px" }}
          label="Name"
          span={4}
        >
          <Paragraph
            editable={
              (isAdmin || permissions.update) && {
                icon: <HighlightOutlined style={{ color: "black" }} />,
                tooltip: "click to edit text",
                onChange: (val) => handleChange({ section: "name", val }),
              }
            }
            style={{ margin: 0 }}
          >
            {editData?.name}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold", width: "50px" }}
          label="Client"
          span={4}
        >
          {isAdmin ? (
            <Select
              style={{ width: "200px", margin: 0 }}
              showSearch
              value={editData?.client_name}
              onSelect={(val) => handleChange({ section: "client_name", val })}
            >
              {clientsData?.map((client, index) => (
                <Select.Option key={index} value={client.name}>
                  {client.name}
                </Select.Option>
              ))}
            </Select>
          ) : (
            editData?.client_name
          )}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold", width: "50px" }}
          label="Is site visible?"
          span={4}
        >
          <Switch
            disabled={isAdmin === false ? permissions.update === false : false}
            onChange={(val) => handleSwitch("is_visible", val)}
            checked={editData?.is_visible}
            size={pageWidth >= 795 ? "default" : "small"}
            style={{ margin: 0 }}
          ></Switch>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold", width: "50px" }}
          label="Is Legacy Site?"
          span={4}
        >
          <Switch
            disabled={isAdmin === false ? permissions.update === false : false}
            onChange={(val) => handleSwitch("is_legacy", val)}
            checked={editData?.is_legacy}
            size={pageWidth >= 795 ? "default" : "small"}
          ></Switch>
        </Descriptions.Item>
        {editData?.is_legacy && (
          <Descriptions.Item
            labelStyle={{ fontWeight: "bold", width: "50px" }}
            label="Legacy Site ID"
            span={4}
          >
            <Paragraph
              editable={
                (isAdmin || permissions.update) && {
                  icon: <HighlightOutlined style={{ color: "black" }} />,
                  tooltip: "click to edit text",
                  onChange: (val) =>
                    handleChange({ section: "legacy_id", val }),
                }
              }
            >
              {editData?.legacy_id}
            </Paragraph>
          </Descriptions.Item>
        )}
      </Descriptions>
    </Card>
  );
};

Basic.propTypes = {
  editData: PropTypes.object,
  clientsData: PropTypes.array,
  EditViewSite: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  editData: state.sites.editData,
  clientsData: state.clients?.data,
});

export default connect(mapStateToProps, {
  EditViewSite,
})(Basic);
