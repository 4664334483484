import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd comoent
import { Button, Descriptions, Modal, Typography, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

// external functions
import {
  ModalCloseImportCalculation,
  saveCalculation,
} from "../../../../../../actions/staffTools/calculations";

const { Paragraph } = Typography;

// actual compoent
const ModalImportCalculation = ({
  modalVis,
  ModalCloseImportCalculation,
  saveCalculation,
  allCalcs,
}) => {
  const [uploadLoading, setUploadLoading] = useState(false);
  const [templateFile, setTemplateFile] = useState(null);
  const [editCalcName, setEditableName] = useState("");
  const [editableDescription, setEditableDescription] = useState("");

  const ClosingSequence = () => {
    setUploadLoading(false);
    setTemplateFile(null);
    ModalCloseImportCalculation();
  };

  const handleOk = () => {
    let index = allCalcs.findIndex(
      (calcItem) => calcItem.calculationName === editCalcName
    );

    if (index === -1) {
      saveCalculation({
        ...templateFile,
        calculationName: editCalcName,
        calculationDescription: editableDescription,
      });
      ClosingSequence();
    } else {
      message.error(
        "💀 A calculation of this name already exists, try change the name to import it 📜"
      );
    }
  };

  const HandleFileUpload = (e) => {
    try {
      const isJSON = e.type === "application/json";

      if (isJSON) {
        setTemplateFile(null);
        setUploadLoading(true);

        const reader = new FileReader();

        reader.onload = (evt) => {
          const templateUploadedFile = JSON.parse(evt.target.result);

          if (
            templateUploadedFile?._id &&
            templateUploadedFile?.calculationName &&
            templateUploadedFile?.calculationDescription
          ) {
            setTemplateFile(templateUploadedFile);
            setEditableName(templateUploadedFile?.calculationName);
            setEditableDescription(
              templateUploadedFile?.calculationDescription
            );
          } else {
            message.error("File is corrupt, missing key information");
          }

          setUploadLoading(false);
        };

        reader.readAsBinaryString(e);
      } else {
        message.error("Incorrect file type, please select a .json type");
      }
    } catch (error) {
      setUploadLoading(false);
      message.error("An error occured, sorry about that, please try again");
    }
  };

  const handleCancel = () => {
    ClosingSequence();
  };

  return (
    <Modal
      visible={modalVis}
      title="Import Calculation"
      onCancel={handleCancel}
      onOk={handleOk}
      okText="Import"
      okButtonProps={{ disabled: templateFile === null }}
      width={"800px"}
      cancelText="Close"
    >
      {templateFile === null ? (
        <Fragment>
          <p style={{ textAlign: "center" }}>
            Click on the button to upload a file
          </p>
          <div style={{ position: "relative", left: "42%" }}>
            <Upload
              beforeUpload={(file) => {
                HandleFileUpload(file);
                return false;
              }}
              maxCount={1}
              accept="application/json"
              showUploadList={false}
              key="upload"
            >
              <Button loading={uploadLoading} icon={<UploadOutlined />}>
                Click to Upload
              </Button>
            </Upload>
          </div>
        </Fragment>
      ) : (
        <Descriptions bordered size="small">
          <Descriptions.Item label="ID">{templateFile?._id}</Descriptions.Item>
          <Descriptions.Item label="Name">
            <Paragraph
              editable={{
                onChange: setEditableName,
              }}
            >
              {editCalcName}
            </Paragraph>
          </Descriptions.Item>
          <Descriptions.Item label="Created At">
            {templateFile?.createdAt}
          </Descriptions.Item>
          <Descriptions.Item label="Description" span={24}>
            <Paragraph
              editable={{
                onChange: setEditableDescription,
              }}
            >
              {editableDescription}
            </Paragraph>
          </Descriptions.Item>
        </Descriptions>
      )}
    </Modal>
  );
};

ModalImportCalculation.propTypes = {
  modalVis: PropTypes.bool,
  ModalCloseImportCalculation: PropTypes.func.isRequired,
  saveCalculation: PropTypes.func.isRequired,
  allCalcs: PropTypes.array,
};

const mapStateToProps = (state) => ({
  modalVis: state.calcs.modalImportVis,
  allCalcs: state.calcs.allCalcs,
});

export default connect(mapStateToProps, {
  ModalCloseImportCalculation,
  saveCalculation,
})(ModalImportCalculation);
