import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Antd components
import { Table, Space, Popconfirm, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

// External Functions
import {
  stepDelete,
  stepModalOpen,
} from "../../../../../actions/staffTools/calculations";

// Actual Componet
const CreateNewConstTable = ({ newCalc, stepDelete, stepModalOpen }) => {
  const handleDelete = (record) => {
    stepDelete(record);
  };

  const handleEdit = (record) => {
    stepModalOpen(record);
  };

  const tableColums = [
    {
      title: "Result Name",
      dataIndex: "resultName",
      key: "resultName",
    },
    {
      title: "Formula",
      dataIndex: "formula",
      key: "formula",
    },
    {
      title: "",
      key: "action",
      width: "70px",
      render: (_, record) => (
        <Space size="small">
          <Fragment>
            <Popconfirm
              title={"Sure you want to delete this step?"}
              onConfirm={() => {
                handleDelete(record);
              }}
            >
              <Tooltip title="Delete this formula">
                <a href="#!">
                  <DeleteOutlined style={{ color: "red" }} />
                </a>
              </Tooltip>
            </Popconfirm>
            <Tooltip title="Edit this formula">
              <a href="#!" onClick={() => handleEdit(record)}>
                <EditOutlined />
              </a>
            </Tooltip>
          </Fragment>
        </Space>
      ),
    },
  ];

  return (
    <Table
      style={{ marginTop: "10px", marginBottom: "10px" }}
      size="small"
      columns={tableColums}
      scroll={{ y: 200 }}
      dataSource={newCalc && newCalc.steps}
    ></Table>
  );
};

CreateNewConstTable.propTypes = {
  newCalc: PropTypes.object,
  stepDelete: PropTypes.func.isRequired,
  stepModalOpen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  newCalc: state.calcs.newCalculation,
});

export default connect(mapStateToProps, { stepDelete, stepModalOpen })(
  CreateNewConstTable
);
