import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// antd comonet
import { Button, Card, Popconfirm, Space, Table } from "antd";
import {
  PlusCircleOutlined,
  RightOutlined,
  DeleteOutlined,
  PhoneFilled,
} from "@ant-design/icons";

// externt functions
import { EditViewSite, ModalAddContactOpen } from "../../../../actions/sites";

// external componet
import ModalAddContact from "../create/modals/ModalAddContact";

// actual componet
const EMSContacts = ({
  pageWidth,
  isAdmin,
  permissions,
  editData,
  EditViewSite,
  ModalAddContactOpen,
}) => {
  const handleDelete = (record) => {
    if (record) {
      let thisSite = { ...editData };

      thisSite.emergencyContact = thisSite.emergencyContact.filter(
        (emsContact) => emsContact.key !== record.key
      );
      EditViewSite(thisSite);
    }
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Surname",
      key: "surname",
      dataIndex: "surname",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      key: "phone",
      dataIndex: "phone",
      width: pageWidth >= 795 ? "150px" : "90px",
    },
    {
      title: "Function",
      key: "function",
      dataIndex: "function",
    },
    {
      title: "",
      key: "action",
      dataIndex: "operation",
      width: pageWidth >= 795 ? "70px" : "90px",
      render: (text, record) => (
        <Space size="small">
          {permissions?.delete | isAdmin ? (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <a href="#!" style={{ color: "red" }}>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          ) : (
            <></>
          )}
          <Button
            shape="circle"
            icon={<RightOutlined />}
            size="small"
            onClick={() => ModalAddContactOpen(record)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Card
      title={
        <span>
          <PhoneFilled /> Emergency Contacts
        </span>
      }
      bordered={false}
    >
      <ModalAddContact />

      <div>
        <p style={{ fontWeight: "bold", margin: 0, padding: 0 }}>
          Emergency Contacts excluding the main site contact
          {permissions?.update | isAdmin ? (
            <span>
              <Button
                type="text"
                style={{ color: "blue", float: "right" }}
                icon={<PlusCircleOutlined />}
                onClick={() => ModalAddContactOpen()}
              >
                Add Contact
              </Button>
            </span>
          ) : (
            <></>
          )}
        </p>
      </div>
      <div
        style={{
          height: pageWidth >= 768 ? "calc(100vh - 31em)" : "100px",
          width: "100%",
          overflow: "auto",
          padding: "0 16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
        }}
      >
        <Table
          size="small"
          pagination={{ pageSize: 50 }}
          bordered
          scroll={{
            x: "100%",
            y: pageWidth >= 768 ? "calc(100vh - 20em)" : "calc(100vh - 20em)",
          }}
          columns={columns}
          dataSource={editData?.emergencyContact ?? []}
        />
      </div>
    </Card>
  );
};

EMSContacts.propTypes = {
  editData: PropTypes.object,
  EditViewSite: PropTypes.func.isRequired,
  ModalAddContactOpen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  editData: state.sites.editData,
});

export default connect(mapStateToProps, {
  EditViewSite,
  ModalAddContactOpen,
})(EMSContacts);
