// Alerts
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// Auth
export const USER_LOADED = "USER_LOADED";
export const USER_PERMISIONS = "USER_PERMISIONS";
export const USER_CLIENT_PERMISIONS = "USER_CLIENT_PERMISIONS";
export const USER_VERIFY_LOAD = "USER_VERIFY_LOAD";
export const USER_VERIFY_DONE = "USER_VERIFY_DONE";
export const USER_PWDCHG_LOAD = "USER_PWDCHG_LOAD";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_MODAL_OPEN = "LOGIN_MODAL_OPEN";
export const LOGIN_MODAL_CLOSE = "LOGIN_MODAL_CLOSE";
export const LOGIN_LOAD = "LOGIN_LOAD";
export const LOGIN_2FA_SUCCESS = "LOGIN_2FA_SUCCESS";
export const LOGIN_2FA_LOAD = "LOGIN_2FA_LOAD";
export const LOGOUT = "LOGOUT";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";

// Notificaitons
export const NOTIF_LOADING = "NOTIF_LOADING";
export const NOTIF_GET_ALL = "NOTIF_GET_ALL";
export const NOTIF_CLEARALL = "NOTIF_CLEARALL";
export const NOTIF_EDIT = "NOTIF_EDIT";
export const NOTIF_MODAL_SITEADD_OPEN = "NOTIF_MODAL_SITEADD_OPEN";
export const NOTIF_MODAL_SITEADD_CLOSE = "NOTIF_MODAL_SITEADD_CLOSE";
export const NOTIF_MODAL_USERADD_OPEN = "NOTIF_MODAL_USERADD_OPEN";
export const NOTIF_MODAL_USERADD_CLOSE = "NOTIF_MODAL_USERADD_CLOSE";
export const NOTIF_MODAL_SITEADD = "NOTIF_MODAL_SITEADD";
export const NOTIF_MODAL_SITEADD_TABLE = "NOTIF_MODAL_SITEADD_TABLE";
export const NOTIF_MODAL_SITEADD_TABLE_ALL = "NOTIF_MODAL_SITEADD_TABLE_ALL";

// Users
export const USERS_LOADING = "USERS_LOADING";
export const USERS_GET_ALL = "USERS_GET_ALL";
export const USERS_CLEARALL = "USERS_CLEARALL";
export const USERS_EDIT = "USERS_EDIT";
export const USERS_EDIT_LOADING = "USERS_EDIT_LOADING";
export const USERS_EDIT_CLEAR = "USERS_EDIT_CLEAR";
export const USERS_MODAL_PERMADD_OPEN = "USERS_MODAL_PERMADD_OPEN";
export const USERS_MODAL_PERMADD_CLOSE = "USERS_MODAL_PERMADD_CLOSE";
export const USERS_MODAL_GROUPADD_CLOSE = "USERS_MODAL_GROUPADD_CLOSE";
export const USERS_MODAL_GROUPADD_OPEN = "USERS_MODAL_GROUPADD_OPEN";
export const USERS_MODAL_SITESADD_OPEN = "USERS_MODAL_SITESADD_OPEN";
export const USERS_MODAL_SITESADD_CLOSE = "USERS_MODAL_SITESADD_CLOSE";
export const USERS_MODAL_SITEADD_TABLE = "USERS_MODAL_SITEADD_TABLE";
export const USERS_MODAL_SITEADD_TABLE_ALL = "USERS_MODAL_SITEADD_TABLE_ALL";
export const USERS_MODAL_SITEADD = "USERS_MODAL_SITEADD";
export const USERS_GET_PROFILEPIC = "USERS_GET_PROFILEPIC";
export const USERS_NEWSITE_UPDATE = "USERS_NEWSITE_UPDATE";
export const USERS_NEWSITE_SAVE = "USERS_NEWSITE_SAVE";

// Sites
export const SITES_LOADING = "SITES_LOADING";
export const SITES_GET_ALL = "SITES_GET_ALL";
export const SITES_CLEARALL = "SITES_CLEARALL";
export const SITES_EDIT = "SITES_EDIT";
export const SITES_NEWSITE_UPDATE = "SITES_NEWSITE_UPDATE";
export const SITES_NEWSITE_SAVE = "SITES_NEWSITE_SAVE";
export const SITES_MODAL_ADDFLR_OPEN = "SITES_MODAL_ADDFLR_OPEN";
export const SITES_MODAL_ADDFLR_CLOSE = "SITES_MODAL_ADDFLR_CLOSE";
export const SITES_MODAL_ADDDASH_CLOSE = "SITES_MODAL_ADDDASH_CLOSE";
export const SITES_MODAL_ADDDASH_OPEN = "SITES_MODAL_ADDDASH_OPEN";
export const SITES_MODAL_ADDCONT_OPEN = "SITES_MODAL_ADDCONT_OPEN";
export const SITES_MODAL_ADDCONT_CLOSE = "SITES_MODAL_ADDCONT_CLOSE";

// calculations
export const CALC_GET_ALL = "CALC_GET_ALL";
export const CALC_LOADING = "CALC_LOADING";
export const CALC_CLEARALL = "CALC_CLEARALL";
export const CALC_UPDATE_LOADING = "CALC_UPDATE_LOADING";
export const CALC_NEWMODAL_OPEN = "CALC_NEWMODAL_OPEN";
export const CALC_NEWMODAL_CLOSED = "CALC_NEWMODAL_CLOSED";
export const CALC_NEWSTEPMODAL_OPEN = "CALC_NEWSTEPMODAL_OPEN";
export const CALC_NEWSTEPMODAL_CLOSE = "CALC_NEWSTEPMODAL_CLOSE";
export const CALC_CONSTMODAL_OPEN = "CALC_CONSTMODAL_OPEN";
export const CALC_CONSTMODAL_CLOSE = "CALC_CONSTMODAL_CLOSE";
export const CALC_NEWOUTPUTMODAL_OPEN = "CALC_NEWOUTPUTMODAL_OPEN";
export const CALC_NEWOUTPUTMODAL_CLOSE = "CALC_NEWOUTPUTMODAL_CLOSE";
export const CALC_CONST_ADD = "CALC_CONST_ADD";
export const CALC_CONST_EDIT = "CALC_CONST_EDIT";
export const CALC_CONST_DELETE = "CALC_CONST_DELETE";
export const CALC_OUTPUT_ADD = "CALC_OUTPUT_ADD";
export const CALC_OUTPUT_DELETE = "CALC_OUTPUT_DELETE";
export const CALC_STEP_ADD = "CALC_STEP_ADD";
export const CALC_STEP_EDIT = "CALC_STEP_EDIT";
export const CALC_STEP_DELETE = "CALC_STEP_DELETE";
export const CALC_MODAL_IMPORT_OPEN = "CALC_MODAL_IMPORT_OPEN";
export const CALC_MODAL_IMPORT_CLOSE = "CALC_MODAL_IMPORT_CLOSE";

// Permissions
export const PERM_LOADING = "PERM_LOADING";
export const PERM_GET_ALL = "PERM_GET_ALL";
export const PERM_CLEARALL = "PERM_CLEARALL";
export const PERM_EDIT = "PERM_EDIT";
export const PERM_MODAL_PERMADD_OPEN = "PERM_MODAL_PERMADD_OPEN";
export const PERM_MODAL_PERMADD_CLOSE = "PERM_MODAL_PERMADD_CLOSE";

// Services
export const SERVICES_LOADING = "SERVICES_LOADING";
export const SERVICES_GET_ALL = "SERVICES_GET_ALL";
export const SERVICES_CLEARALL = "SERVICES_CLEARALL";
export const SERVICES_EDIT = "SERVICES_EDIT";
export const SERVICES_MODAL_PERMADD_OPEN = "SERVICES_MODAL_PERMADD_OPEN";
export const SERVICES_MODAL_PERMADD_CLOSE = "SERVICES_MODAL_PERMADD_CLOSE";

// Roles
export const ROLES_LOADING = "ROLES_LOADING";
export const ROLES_GET_ALL = "ROLES_GET_ALL";
export const ROLES_CLEARALL = "ROLES_CLEARALL";
export const ROLES_EDIT = "ROLES_EDIT";
export const ROLES_MODAL_PERMADD_OPEN = "ROLES_MODAL_PERMADD_OPEN";
export const ROLES_MODAL_PERMADD_CLOSE = "ROLES_MODAL_PERMADD_CLOSE";
export const ROLES_MODAL_ADDROLE_CLOSE = "ROLES_MODAL_ADDROLE_CLOSE";
export const ROLES_MODAL_ADDROLE_OPEN = "ROLES_MODAL_ADDROLE_OPEN";

// Groups
export const GROUPS_LOADING = "GROUPS_LOADING";
export const GROUPS_GET_ALL = "GROUPS_GET_ALL";
export const GROUPS_CLEARALL = "GROUPS_CLEARALL";
export const GROUPS_EDIT = "GROUPS_EDIT";
export const GROUPS_MODAL_GROUPADD_OPEN = "GROUPS_MODAL_GROUPADD_OPEN";
export const GROUPS_MODAL_GROUPADD_CLOSE = "GROUPS_MODAL_GROUPADD_CLOSE";
export const GROUPS_MODAL_SITEADD_CLOSE = "GROUPS_MODAL_SITEADD_CLOSE";
export const GROUPS_MODAL_SITEADD_OPEN = "GROUPS_MODAL_SITEADD_OPEN";
export const GROUPS_MODAL_SITEADD_TABLE = "GROUPS_MODAL_SITEADD_TABLE";
export const GROUPS_MODAL_SITEADD_TABLE_ALL = "GROUPS_MODAL_SITEADD_TABLE_ALL";
export const GROUPS_MODAL_SITEADD = "GROUPS_MODAL_SITEADD";

// Clients
export const CLIENTS_LOADING = "CLIENTS_LOADING";
export const CLIENTS_GET_ALL = "CLIENTS_GET_ALL";
export const CLIENTS_CLEARALL = "CLIENTS_CLEARALL";
export const CLIENTS_EDIT = "CLIENTS_EDIT";
export const CLIENTS_MODAL_NEWCLIENT_OPEN = "CLIENTS_MODAL_NEWCLIENT_OPEN";
export const CLIENTS_MODAL_NEWCLIENT_CLOSE = "CLIENTS_MODAL_NEWCLIENT_CLOSE";
export const CLIENTS_GET_LOGO = "CLIENTS_GET_LOGO";
export const CLIENTS_MODAL_ADDSERVICE_CLOSE = "CLIENTS_MODAL_ADDSERVICE_CLOSE";
export const CLIENTS_MODAL_ADDSERVICE_OPEN = "CLIENTS_MODAL_ADDSERVICE_OPEN";

// MISC
export const MISC_CHANGE_WIDTH = "MISC_CHANGE_WIDTH";
export const MISC_CHANGE_HEIGHT = "MISC_CHANGE_HEIGHT";
export const MISC_GET_REPORTRULES = "MISC_GET_REPORTRULES";
export const MISC_GET_REPORTRULES_LOADING = "MISC_GET_REPORTRULES_LOADING";
export const MISC_REPORTGEN_LOADING = "MISC_REPORTGEN_LOADING";
export const MISC_REPORTGEN_DATA = "MISC_REPORTGEN_DATA";

// Designation
export const DESIG_GET_ALL = "DESIG_GET_ALL";
export const DESIG_LOADING = "DESIG_LOADING";
export const DESIG_CLEARALL = "DESIG_CLEARALL";
export const DESIG_MODAL_ADDNEW_OPEN = "DESIG_MODAL_ADDNEW_OPEN";
export const DESIG_MODAL_ADDNEW_CLOSE = "DESIG_MODAL_ADDNEW_CLOSE";
export const DESIG_EDIT = "DESIG_EDIT";

// Department
export const DEPART_GET_ALL = "DEPART_GET_ALL";
export const DEPART_LOADING = "DEPART_LOADING";
export const DEPART_CLEARALL = "DEPART_CLEARALL";
export const DEPART_MODAL_ADDNEW_OPEN = "DEPART_MODAL_ADDNEW_OPEN";
export const DEPART_MODAL_ADDNEW_CLOSE = "DEPART_MODAL_ADDNEW_CLOSE";
export const DEPART_EDIT = "DEPART_EDIT";

// Regions
export const REGIONS_GET_ALL = "REGIONS_GET_ALL";
export const REGIONS_LOADING = "REGIONS_LOADING";
export const REGIONS_CLEARALL = "REGIONS_CLEARALL";
export const REGIONS_MODAL_ADDNEW_OPEN = "REGIONS_MODAL_ADDNEW_OPEN";
export const REGIONS_MODAL_ADDNEW_CLOSE = "REGIONS_MODAL_ADDNEW_CLOSE";
export const REGIONS_EDIT = "REGIONS_EDIT";

// Sales Call Register
export const SALESCALL_LOADING = "SALESCALL_LOADING";
export const SALESCALL_GET_ALL = "SALESCALL_GET_ALL";
export const SALESCALL_CLEARALL = "SALESCALL_CLEARALL";
export const SALESCALL_EDIT = "SALESCALL_EDIT";
export const SALESCALL_MODAL_ADDNEWCLIENT_OPEN =
  "SALESCALL_MODAL_ADDNEWCLIENT_OPEN";
export const SALESCALL_MODAL_ADDNEWCLIENT_CLOSE =
  "SALESCALL_MODAL_ADDNEWCLIENT_CLOSE";

// Sales MySales
export const SALESMY_LOADING = "SALESMY_LOADING";
export const SALESMY_DASHLOADING = "SALESMY_DASHLOADING";
export const SALESMY_GET_ALL = "SALESMY_GET_ALL";
export const SALESMY_GET_DASH = "SALESMY_GET_DASH";
export const SALESMY_CLEARALL = "SALESMY_CLEARALL";
export const SALESMY_EDIT = "SALESMY_EDIT";
export const SALESMY_EDIT_VIEW = "SALESMY_EDIT_VIEW";
export const SALESMY_MODAL_EDITSALE_OPEN = "SALESMY_MODAL_EDITSALE_OPEN";
export const SALESMY_MODAL_EDITSALE_CLOSE = "SALESMY_MODAL_EDITSALE_CLOSE";
export const SALESMY_MODAL_ADDSALE_OPEN = "SALESMY_MODAL_ADDSALE_OPEN";
export const SALESMY_MODAL_ADDSALE_CLOSE = "SALESMY_MODAL_ADDSALE_CLOSE";
export const SALESMY_MODAL_ADDNEWCLIENT_OPEN =
  "SALESMY_MODAL_ADDNEWCLIENT_OPEN";
export const SALESMY_MODAL_ADDNEWCLIENT_CLOSE =
  "SALESMY_MODAL_ADDNEWCLIENT_CLOSE";

// Sales Clients
export const SALESCLIENTS_LOADING = "SALESCLIENTS_LOADING";
export const SALESCLIENTS_GET_ALL = "SALESCLIENTS_GET_ALL";
export const SALESCLIENTS_CLEARALL = "SALESCLIENTS_CLEARALL";
export const SALESCLIENTS_EDIT = "SALESCLIENTS_EDIT";
export const SALESCLIENTS_MODAL_ADDNEWCLIENT_OPEN =
  "SALESCLIENTS_MODAL_ADDNEWCLIENT_OPEN";
export const SALESCLIENTS_MODAL_ADDNEWCLIENT_CLOSE =
  "SALESCLIENTS_MODAL_ADDNEWCLIENT_CLOSE";

// Config tool - Types
export const CONFIGTYPE_LOADING = "CONFIGTYPE_LOADING";
export const CONFIGTYPE_GET_ALL = "CONFIGTYPE_GET_ALL";
export const CONFIGTYPE_GET_ALL_DETAILED = "CONFIGTYPE_GET_ALL_DETAILED";
export const CONFIGTYPE_GET_SINGLE = "CONFIGTYPE_GET_SINGLE";
export const CONFIGTYPE_CLEARALL = "CONFIGTYPE_CLEARALL";
export const CONFIGTYPE_MODALNEW_OPEN = "CONFIGTYPE_MODALNEW_OPEN";
export const CONFIGTYPE_MODALNEW_CLOSE = "CONFIGTYPE_MODALNEW_CLOSE";
export const CONFIGTYPE_MODALNEWITEM_OPEN = "CONFIGTYPE_MODALNEWITEM_OPEN";
export const CONFIGTYPE_MODALNEWITEM_CLOSE = "CONFIGTYPE_MODALNEWITEM_CLOSE";

// Config tool - Maunufactures
export const CONFIGMAN_LOADING = "CONFIGMAN_LOADING";
export const CONFIGMAN_GET_ALL = "CONFIGMAN_GET_ALL";
export const CONFIGMAN_CLEARALL = "CONFIGMAN_CLEARALL";
export const CONFIGMAN_MODALNEW_OPEN = "CONFIGMAN_MODALNEW_OPEN";
export const CONFIGMAN_MODALNEW_CLOSE = "CONFIGMAN_MODALNEW_CLOSE";

// Config tool - Templates
export const CONFIGTEMP_LOADING = "CONFIGTEMP_LOADING";
export const CONFIGTEMP_GET_ALL = "CONFIGTEMP_GET_ALL";
export const CONFIGTEMP_GET_SINGLE = "CONFIGTEMP_GET_SINGLE";
export const CONFIGTEMP_MODALCALC_CLOSE = "CONFIGTEMP_MODALCALC_CLOSE";
export const CONFIGTEMP_MODALCALC_OPEN = "CONFIGTEMP_MODALCALC_OPEN";
export const CONFIGTEMP_MODALNEWBACNET_OPEN = "CONFIGTEMP_MODALNEWBACNET_OPEN";
export const CONFIGTEMP_MODALNEWBACNET_CLOSE =
  "CONFIGTEMP_MODALNEWBACNET_CLOSE";
export const CONFIGTEMP_MODAL_IMPORT = "CONFIGTEMP_MODAL_IMPORT";
export const CONFIGTEMP_CLEARALL = "CONFIGTEMP_CLEARALL";
export const CONFIGTEMP_MODALNEW_OPEN = "CONFIGTEMP_MODALNEW_OPEN";
export const CONFIGTEMP_MODALNEW_CLOSE = "CONFIGTEMP_MODALNEW_CLOSE";
export const CONFIGTEMP_MODALNEWSNMP_CLOSE = "CONFIGTEMP_MODALNEWSNMP_CLOSE";
export const CONFIGTEMP_MODALNEWSNMP_OPEN = "CONFIGTEMP_MODALNEWSNMP_OPEN";
export const CONFIGTEMP_MODALEXTRADETAILS_OPEN =
  "CONFIGTEMP_MODALEXTRADETAILS_OPEN";
export const CONFIGTEMP_MODALEXTRADETAILS_CLOSE =
  "CONFIGTEMP_MODALEXTRADETAILS_CLOSE";
export const CONFIGTEMP_MODALBATCH_CLOSE = "CONFIGTEMP_MODALBATCH_CLOSE";
export const CONFIGTEMP_MODALNEWINVERTASCII_OPEN =
  "CONFIGTEMP_MODALNEWINVERTASCII_OPEN";
export const CONFIGTEMP_MODALBATCH_OPEN = "CONFIGTEMP_MODALBATCH_OPEN";
export const CONFIGTEMP_MODALNEWINVERTASCII_CLOSE =
  "CONFIGTEMP_MODALNEWINVERTASCII_CLOSE";
export const CONFIGTEMP_MODALNEWRF868_OPEN = "CONFIGTEMP_MODALNEWRF868_OPEN";
export const TEMP_CALC_MODALOUTPUT_OPEN = "TEMP_CALC_MODALOUTPUT_OPEN";
export const TEMP_CALC_MODALOUTPUT_CLOSE = "TEMP_CALC_MODALOUTPUT_CLOSE";
export const TEMP_CALC_MODALSTEP_OPEN = "TEMP_CALC_MODALSTEP_OPEN";
export const TEMP_CALC_MODALSTEP_CLOSE = "TEMP_CALC_MODALSTEP_CLOSE";
export const TEMP_CALC_MODALCONST_CLOSE = "TEMP_CALC_MODALCONST_CLOSE";
export const TEMP_CALC_NEWCALC = "TEMP_CALC_NEWCALC";
export const CONFIGTEMP_MODALNEWRF868_CLOSE = "CONFIGTEMP_MODALNEWRF868_CLOSE";
export const CONFIGTEMP_MODALADDRESS_OPEN = "CONFIGTEMP_MODALADDRESS_OPEN";
export const TEMP_CALC_MODALCONST_OPEN = "TEMP_CALC_MODALCONST_OPEN";
export const CONFIGTEMP_MODALADDRESS_CLOSE = "CONFIGTEMP_MODALADDRESS_CLOSE";
export const CONFIGTEMP_MODALALARM_CLOSE = "CONFIGTEMP_MODALALARM_CLOSE";
export const CONFIGTEMP_MODALALARM_OPEN = "CONFIGTEMP_MODALALARM_OPEN";
export const CONFIGTEMP_MODALALARMCOPY_OPEN = "CONFIGTEMP_MODALALARMCOPY_OPEN";
export const CONFIGTEMP_MODALALARMCOPY_CLOSE =
  "CONFIGTEMP_MODALALARMCOPY_CLOSE";

// Tasks
export const TASK_LIST_LOADING = "TASK_LIST_LOADING";
export const TASK_CREATE = "TASK_CREATE";
export const TASK_UPDATE = "TASK_UPDATE";

export const TASK_ADD_ATTACHMENTS = "TASK_ADD_ATTACHMENTS";
export const TASK_GET_ATTACHMENTS = "TASK_GET_ATTACHMENTS";
export const UPDATE_TASK_ATTACHMENT_DETAILS = "UPDATE_TASK_ATTACHMENT_DETAILS";
export const DELETE_TASK_ATTACHMENT = "DELETE_TASK_ATTACHMENT";

export const TASK_GET_SINGLE = "TASK_GET_SINGLE";
export const TASK_GET_ALL = "TASK_GET_ALL";
export const GET_USER_TASKS = "GET_USER_TASKS";

export const TASK_SET_SELECTED = "TASK_SET_SELECTED";
export const TASK_UPDATE_SELECTED_TASK = "TASK_UPDATE_SELECTED_TASK";

export const TASK_ADD_COLLABORATOR = "TASK_ADD_COLLABORATOR";
export const TASK_REMOVE_COLLABORATOR = "TASK_REMOVE_COLLABORATOR";

export const TASK_ADD_MULTIPLE_COLLABORATOR = "TASK_ADD_MULTIPLE_COLLABORATOR";

export const GET_TASK_CHAT = "GET_TASK_CHAT";
export const TASK_CHAT_LOADED = "TASK_CHAT_LOADED";

export const GET_TASK_CHAT_MESSAGES = "GET_TASK_CHAT_MESSAGES";
export const CLEAR_TASK_CHAT_MESSAGES = "CLEAR_TASK_CHAT_MESSAGES";

// Chat
export const GET_USER_CHAT_LIST = "GET_USER_CHAT_LIST";
export const GET_CHAT_MESSAGES = "GET_CHAT_MESSAGES";
export const SET_HAS_LOADED_CHAT_MESSAGES = "SET_HAS_LOADED_CHAT_MESSAGES";
export const SENDING_MESSAGE = "SENDING_MESSAGE";
export const NEW_CHAT_MESSAGE_RECEIVED = "NEW_CHAT_MESSAGE_RECEIVED";

export const IS_CREATING_CHAT = "IS_CREATING_CHAT";

export const CREATE_CHAT = "CREATE_CHAT";
