import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd component
import { Card, Form, Button, Select, Input } from "antd";

// external functions
import { NewUserUpdate, NewUserSave } from "../../../../../actions/users";

const { Option } = Select;

// actual componet
const UserSite = ({
  current,
  setCurrent,
  newUserData,
  isAdmin,
  NewUserUpdate,
  pageWidth,
  availableClients,
  availableGroups,
  thisUser,
  availableSites,
  NewUserSave,
}) => {
  const [formSite] = Form.useForm();
  const [formData, setFormData] = useState(null);

  const isStaff =
    newUserData?.role.name.includes("Staff") ||
    newUserData?.role.name === "Administrator";

  const handleNext = () => {
    const data = formSite.getFieldsValue();

    let currentUserData = { ...newUserData };

    let ClientData = "";

    if (isAdmin) {
      ClientData = availableClients.filter(
        (client) => client.name === data.client_name
      );

      currentUserData.client_id = ClientData[0]._id;
      currentUserData.client_name = ClientData[0].name;
    } else {
      ClientData = availableClients.filter(
        (client) => client._id === thisUser.client_id
      );

      currentUserData.client_id = ClientData[0]._id;
      currentUserData.client_name = ClientData[0].name;
    }

    currentUserData.client_groups = [];
    currentUserData.default_site = data.default_site;

    if (data.groups) {
      availableGroups.map((group) => {
        data.groups.map((item) => {
          if (item === group.name) {
            let newArrayItem = { ...group };

            delete newArrayItem.sites;
            currentUserData.client_groups.push({ ...newArrayItem });
          }

          return true;
        });
        return true;
      });
    }

    if (isStaff) {
      currentUserData.staff.country = availableSites.find(
        (site) => site.name === data.default_site
      )?.address?.country;
    }

    if (data.sites) {
      currentUserData.sites = [...data.sites];
    }

    currentUserData.contact_number =
      "+" +
      currentUserData.contact_number.code +
      currentUserData.contact_number.phone;

    setCurrent(current + 1);
    setFormData(null);
    NewUserSave(currentUserData);
    NewUserUpdate(null);
  };

  if (formData === null && newUserData?.client_name) {
    setFormData(newUserData.site_contact);

    formSite.setFieldsValue({ client_name: newUserData.client_name });

    let GroupNames = [];

    if (newUserData?.client_name) {
      newUserData?.client_name.map((item) => GroupNames.push(item.name));
    }

    formSite.setFieldsValue({ groups: newUserData.client_name });
  }

  if (isAdmin === undefined || isAdmin === false)
    formSite.setFieldsValue({ client_name: thisUser.client_name });

  return (
    <Card
      title={<p style={{ fontWeight: "bold", marginBottom: "0px" }}>Sites</p>}
      extra={[
        <Button
          style={{ margin: "0 8px" }}
          onClick={() => setCurrent(current - 1)}
        >
          Previous
        </Button>,
        <Button
          type="primary"
          onClick={() => formSite.validateFields().then(() => handleNext())}
        >
          Next
        </Button>,
      ]}
      style={{ marginTop: pageWidth > 795 ? "15px" : "5px", width: "100%" }}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 5 }}
        size={pageWidth >= 795 ? "middle" : "small"}
        form={formSite}
        onValuesChange={(_, all) => {
          if (all.default_site !== undefined && all.client_name === undefined) {
            formSite.setFieldsValue({ default_site: undefined });
          }

          if (all.sites !== undefined && all.client_name === undefined) {
            formSite.setFieldsValue({ sites: undefined });
          }
          setFormData(all);
        }}
      >
        <Form.Item
          label={
            <span style={{ color: "black" }}>
              {isStaff ? "Company" : "Client Name"}
            </span>
          }
          name={"client_name"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              required: true,
              message: `Please select a ${isStaff ? "company" : "client"}!`,
            },
          ]}
        >
          {isAdmin ? (
            <Select allowClear showSearch>
              {availableClients?.map((client) => (
                <Option key={client._id} value={client.name}>
                  {client.name}
                </Option>
              ))}
            </Select>
          ) : (
            <Input disabled />
          )}
        </Form.Item>
        <Form.Item
          label={
            <span style={{ color: "black" }}>
              {isStaff ? "Branch" : "Default Site"}
            </span>
          }
          name={"default_site"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              required: true,
              message: `Please select a ${isStaff ? "branch" : "site"}!`,
            },
          ]}
        >
          {isAdmin ? (
            <Select allowClear showSearch>
              {formData?.client_name &&
                availableSites?.map(
                  (site) =>
                    site.client_name === formData?.client_name && (
                      <Option key={site._id} value={site.name}>
                        {site.name}
                      </Option>
                    )
                )}
            </Select>
          ) : (
            <Select allowClear showSearch>
              {availableSites?.map(
                (site) =>
                  site.client_id === thisUser?.client_id && (
                    <Option key={site._id} value={site.name}>
                      {site.name}
                    </Option>
                  )
              )}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "black" }}>Sites</span>}
          name={"sites"}
          tooltip="This is the sites the user will be able to view"
          extra="Can select multiple sites"
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              required: true,
              message: "Please select at least one site!",
            },
          ]}
        >
          {isAdmin ? (
            <Select
              allowClear
              showSearch
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (
                  option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {formData?.client_name &&
                availableSites?.map(
                  (site) =>
                    site.client_name === formData?.client_name && (
                      <Option key={site._id} value={site._id} title={site.name}>
                        {site.name}
                      </Option>
                    )
                )}
            </Select>
          ) : (
            <Select
              allowClear
              showSearch
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (
                  option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {availableSites?.map(
                (site) =>
                  site.client_id === thisUser?.client_id && (
                    <Option key={site._id} value={site._id} title={site.name}>
                      {site.name}
                    </Option>
                  )
              )}
            </Select>
          )}
        </Form.Item>
        {!isStaff && (
          <Form.Item
            label={<span style={{ color: "black" }}>Groups</span>}
            name={"groups"}
            extra="Can select multiple group"
            style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          >
            {isAdmin ? (
              <Select allowClear showSearch mode="multiple">
                {availableGroups?.map((group) => (
                  <Option key={group._id} value={group.name}>
                    {group.name}
                  </Option>
                ))}
              </Select>
            ) : (
              <Select allowClear showSearch mode="multiple">
                {availableGroups?.map(
                  (group) =>
                    group.client_id === thisUser?.client_id && (
                      <Option key={group._id} value={group.name}>
                        {group.name}
                      </Option>
                    )
                )}
              </Select>
            )}
          </Form.Item>
        )}
      </Form>
    </Card>
  );
};

UserSite.propTypes = {
  newUserData: PropTypes.object,
  NewUserUpdate: PropTypes.func.isRequired,
  NewUserSave: PropTypes.func.isRequired,
  availableClients: PropTypes.array,
  availableGroups: PropTypes.array,
  availableSites: PropTypes.array,
  thisUser: PropTypes.object,
};

const mapStateToProps = (state) => ({
  newUserData: state.users.newUserData,
  availableClients: state.clients.data,
  availableGroups: state.groups.data,
  availableSites: state.sites.data,
  thisUser: state.auth.user,
});

export default connect(mapStateToProps, { NewUserUpdate, NewUserSave })(
  UserSite
);
