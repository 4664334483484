import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";

// andt components
import { Modal, Form, Input } from "antd";

// external functions
import {
  ModalAddDashboardClose,
  NewSiteUpdate,
  EditViewSite,
} from "../../../../../actions/sites";

// actual component
const ModalAddDashboard = ({
  modalVis,
  ModalAddDashboardClose,
  pageWidth,
  modalData,
  editData,
  EditViewSite,
}) => {
  const inputRef = useRef();
  const formLoaded = useRef(false);
  const [formDetails] = Form.useForm();

  const handleOk = () => {
    const id = uuid();
    let data = formDetails.getFieldsValue();

    data.name = data.name.trim();
    data.url = data.url.trim();

    let siteData = { ...editData };

    if (modalData === null) {
      if (siteData?.trendsDashBoard === undefined)
        siteData.trendsDashBoard = [];

      siteData.trendsDashBoard.push({ ...data, key: id });
    } else {
      let index = siteData?.trendsDashBoard.findIndex(
        (trend) => trend.key === modalData.key
      );

      if (index !== -1) {
        siteData.trendsDashBoard[index] = { ...data, key: modalData.key };
      }
    }
    EditViewSite({ ...siteData });

    ModalAddDashboardClose();
  };

  const handleCancel = () => {
    ModalAddDashboardClose();
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus({
        cursor: "start",
      });
    }, 100);
  }, [modalVis]);

  if (modalData !== null && modalVis === true && formLoaded.current === false) {
    formLoaded.current = true;
    formDetails.setFieldsValue({ name: modalData.name, url: modalData.url });
  }

  return (
    <Modal
      title={modalData === null ? "Add new Dashboard" : "Edit Dashboard"}
      onCancel={handleCancel}
      okText="Add"
      afterClose={() => {
        formLoaded.current = false;
        formDetails.resetFields();
      }}
      onOk={() => formDetails.validateFields().then(() => handleOk())}
      visible={modalVis}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
        size="small"
        form={formDetails}
      >
        <Form.Item
          label={<span style={{ color: "black" }}>Dashboard Name</span>}
          name={"name"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              required: true,
              message: "Please input a valid name!",
            },
          ]}
        >
          <Input ref={inputRef} />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "black" }}>URL</span>}
          name={"url"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              required: true,
              type: "url",
              message: "Please enter a valid URL!",
            },
          ]}
        >
          <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalAddDashboard.propTypes = {
  modalVis: PropTypes.bool,
  modalData: PropTypes.object,
  ModalAddDashboardClose: PropTypes.func.isRequired,
  EditViewSite: PropTypes.func.isRequired,
  editData: PropTypes.object,
  pageWidth: PropTypes.number,
};

const mapStateToProps = (state) => ({
  modalVis: state.sites.modalAddDashVis,
  modalData: state.sites.modalAddDashData,
  editData: state.sites.editData,
  pageWidth: state.misc?.width,
});

export default connect(mapStateToProps, {
  ModalAddDashboardClose,
  NewSiteUpdate,
  EditViewSite,
})(ModalAddDashboard);
