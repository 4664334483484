import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// antd componetn
import {
  Table,
  Space,
  Popconfirm,
  Tooltip,
  Button,
  Dropdown,
  Menu,
} from "antd";
import {
  DeleteOutlined,
  RightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";

// external functions
import { DeleteSpecificSite, UpdateSpecificSite } from "../../../actions/sites";

// actual Component
const SitesTable = ({
  permissions,
  isAdmin,
  data,
  loading,
  userClientID,
  pageWidth,
  DeleteSpecificSite,
  Clientdata,
  searchTerm,
  UpdateSpecificSite,
}) => {
  const [tableFilters, setTableFilters] = useState(
    JSON.parse(sessionStorage.getItem("sitesTableFilters"))
  );

  const handleDelete = (record) => {
    DeleteSpecificSite(record._id);
  };

  const handleTableChanges = (pagination, filters, sorter) => {
    sessionStorage.setItem("sitesTableFilters", JSON.stringify(filters));
    setTableFilters(filters);
  };

  const handleMenuClick = (e, siteData) => {
    switch (e.key) {
      case "1":
        siteData.is_visible ^= true;
        UpdateSpecificSite(siteData._id, siteData);
        break;
      default:
        break;
    }
  };

  const menu = (record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        <Menu.Item key="1">
          {record?.is_visible ? "Disable" : "Enable"}
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Client",
      key: "client",
      dataIndex: "client_name",
      filters: Clientdata?.sort((a, b) => a.name.localeCompare(b.name)).map(
        (client) => {
          return { text: client.name, value: client.name };
        }
      ),
      filteredValue: tableFilters?.client || null,
      onFilter: (value, record) => record.client_name === value,
    },
    ...(isAdmin === true
      ? [
          {
            title: "Legacy",
            key: "legacy",
            width: "80px",
            align: "center",
            dataIndex: "is_legacy",
            responsive: ["md"],
            filters: [
              { text: "Legacy", value: true },
              { text: "Not Legacy", value: false },
            ],
            onFilter: (value, record) => {
              let isLegItem;

              if (record.is_legacy === undefined) isLegItem = false;
              else isLegItem = record.is_legacy;

              if (isLegItem === value) {
                return record;
              }
            },
            filteredValue: tableFilters?.legacy || null,
            render: (text, record) =>
              text ? (
                <CheckCircleOutlined style={{ color: "green" }} />
              ) : (
                <CloseCircleOutlined style={{ color: "red" }} />
              ),
          },
          {
            title: "Legacy ID",
            key: "legacyID",
            width: pageWidth >= 795 ? "100px" : "60px",
            sorter: (a, b) => a.legacy_id - b.legacy_id,
            defaultSortOrder: "ascend",
            dataIndex: "legacy_id",
          },
          {
            title: "Visible",
            key: "visible",
            width: "80px",
            align: "center",
            responsive: ["md"],
            dataIndex: "is_visible",
            filters: [
              { text: "Yes", value: true },
              { text: "No", value: false },
            ],
            onFilter: (value, record) => {
              let isItemVis;

              if (record.is_visible === undefined) isItemVis = false;
              else isItemVis = record.is_visible;

              if (isItemVis === value) {
                return record;
              }
            },
            filteredValue: tableFilters?.visible || null,
            render: (text, record) =>
              text ? (
                <CheckCircleOutlined style={{ color: "green" }} />
              ) : (
                <CloseCircleOutlined style={{ color: "red" }} />
              ),
          },
        ]
      : []),
    {
      title: "Address",
      key: "address",
      responsive: ["lg"],
      dataIndex: "address",
      render: (text, record) =>
        `${text?.street} ${text?.suburb} ${text.city} ${text.province} ${text.country}`,
    },
    {
      title: "Contact",
      key: "contact_number",
      responsive: ["lg"],
      dataIndex: ["site_contact", "name"],
    },
    {
      title: "",
      key: "action",
      dataIndex: "operation",
      width: pageWidth >= 795 ? "100px" : "90px",
      render: (text, record) => (
        <Space size="small">
          <Fragment>
            {permissions?.update | isAdmin ? (
              <Dropdown overlay={() => menu(record)} placement="bottomCenter">
                <SettingOutlined />
              </Dropdown>
            ) : (
              <></>
            )}
            {permissions?.delete | isAdmin ? (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record)}
              >
                <Tooltip title="Delete site">
                  <a href="#!" style={{ color: "red" }}>
                    <DeleteOutlined />
                  </a>
                </Tooltip>
              </Popconfirm>
            ) : (
              <></>
            )}
            <Tooltip title="Edit/view this site">
              <Link to={{ pathname: `/admin/site/${record._id}` }}>
                <Button shape="circle" icon={<RightOutlined />} size="small" />
              </Link>
            </Tooltip>
          </Fragment>
        </Space>
      ),
    },
  ];

  return (
    <Table
      style={{ marginTop: pageWidth >= 768 ? "15px" : "1px" }}
      loading={loading}
      columns={columns}
      dataSource={
        isAdmin === true
          ? searchTerm !== null
            ? data
                ?.filter(
                  (item) =>
                    item?.name.toLowerCase().includes(searchTerm) ||
                    item?.site_contact.name
                      .toLowerCase()
                      .includes(searchTerm) ||
                    item?.client_name.toLowerCase().includes(searchTerm)
                )
                .map((item) => {
                  item.key = item._id;

                  return item;
                })
            : data?.map((item) => {
                item.key = item._id;

                return item;
              })
          : searchTerm !== null
          ? data
              ?.filter(
                (item) =>
                  item?.name.toLowerCase().includes(searchTerm) ||
                  item?.site_contact.name.toLowerCase().includes(searchTerm) ||
                  item?.client_name.toLowerCase().includes(searchTerm)
              )
              .map((item) => {
                item.key = item._id;

                return item;
              })
          : data?.filter((item) => item?.client_id === userClientID)
      }
      size="small"
      pagination={{ pageSize: 50 }}
      bordered
      scroll={{
        x: "100%",
        y: pageWidth >= 768 ? "calc(100vh - 20em)" : "calc(100vh - 20em)",
      }}
      onChange={handleTableChanges}
    ></Table>
  );
};

SitesTable.propTypes = {
  loading: PropTypes.bool,
  userClientID: PropTypes.string,
  data: PropTypes.array,
  Clientdata: PropTypes.array,
  DeleteSpecificSite: PropTypes.func.isRequired,
  UpdateSpecificSite: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  data: state.sites.data,
  Clientdata: state.clients.data,
  userClientID: state.auth?.user?.client_id,
  loading: state.sites.loading,
});

export default connect(mapStateToProps, {
  DeleteSpecificSite,
  UpdateSpecificSite,
})(SitesTable);
