import {
  CALC_CLEARALL,
  CALC_CONSTMODAL_CLOSE,
  CALC_CONSTMODAL_OPEN,
  CALC_CONST_ADD,
  CALC_CONST_DELETE,
  CALC_CONST_EDIT,
  CALC_GET_ALL,
  CALC_LOADING,
  CALC_MODAL_IMPORT_CLOSE,
  CALC_MODAL_IMPORT_OPEN,
  CALC_NEWMODAL_CLOSED,
  CALC_NEWMODAL_OPEN,
  CALC_NEWOUTPUTMODAL_CLOSE,
  CALC_NEWOUTPUTMODAL_OPEN,
  CALC_NEWSTEPMODAL_CLOSE,
  CALC_NEWSTEPMODAL_OPEN,
  CALC_OUTPUT_ADD,
  CALC_OUTPUT_DELETE,
  CALC_STEP_ADD,
  CALC_STEP_DELETE,
  CALC_STEP_EDIT,
  CALC_UPDATE_LOADING,
} from "../../actions/types";

const initalState = {
  loading: false,
  allCalcs: null,
  updateLoading: false,
  createNewModalVis: false,
  newStepModalVis: false,
  newStepEdit: false,
  newStepEditData: null,
  constantModalData: null,
  constantModalVis: false,
  createOutputModalVis: false,
  createOutputModalEdit: null,
  createNewModalEdit: false,
  modalImportVis: false,
  newCalculation: null,
};

export default function calcs(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case CALC_MODAL_IMPORT_OPEN:
      return { ...state, modalImportVis: true };
    case CALC_MODAL_IMPORT_CLOSE:
      return { ...state, modalImportVis: false };
    case CALC_STEP_EDIT:
      let newStep = { ...state.newCalculation };

      newStep.steps = payload;

      return { ...state, newCalculation: newStep };
    case CALC_STEP_ADD:
      let tempNewStep = { ...state.newCalculation };
      let indexOfStep = undefined;

      if (tempNewStep.steps === undefined) {
        tempNewStep.steps = [];
        indexOfStep = -1;
      } else {
        indexOfStep = tempNewStep.steps.findIndex(
          (step) => step.resultName === payload.resultName
        );
      }

      if (indexOfStep !== -1) {
        tempNewStep.steps[indexOfStep] = payload;

        tempNewStep.steps = [...tempNewStep.steps];
      } else {
        tempNewStep.steps = [...tempNewStep.steps, payload];
      }

      return { ...state, newCalculation: tempNewStep };
    case CALC_STEP_DELETE:
      let tempNewStepDelete = { ...state.newCalculation };

      tempNewStepDelete.steps = tempNewStepDelete.steps.filter(
        (item) => item.resultName !== payload.resultName
      );

      return { ...state, newCalculation: tempNewStepDelete };
    case CALC_OUTPUT_ADD:
      let tempNewOuputCalc = { ...state.newCalculation };

      if (tempNewOuputCalc.outputs === undefined) {
        tempNewOuputCalc.outputs = [];
      }

      tempNewOuputCalc.outputs = [...tempNewOuputCalc.outputs, payload];

      return { ...state, newCalculation: tempNewOuputCalc };
    case CALC_OUTPUT_DELETE:
      let tempNewCalcOutputDelete = { ...state.newCalculation };

      tempNewCalcOutputDelete.outputs = tempNewCalcOutputDelete.outputs.filter(
        (item) => item.key !== payload.key
      );

      return { ...state, newCalculation: tempNewCalcOutputDelete };
    case CALC_CONST_EDIT:
      let newCalc = { ...state.newCalculation };

      newCalc.constant = payload;

      return { ...state, newCalculation: newCalc };
    case CALC_CONST_ADD:
      let tempNewCalc = { ...state.newCalculation };

      if (tempNewCalc.constant === undefined) {
        tempNewCalc.constant = [];
      }

      if (payload.edit === false) {
        tempNewCalc.constant = [...tempNewCalc.constant, payload.data];
      } else {
        let index = tempNewCalc.constant.findIndex(
          (cons) => cons.key === payload.data.key
        );

        console.log(index);
        console.log(payload);

        if (index !== -1) tempNewCalc.constant[index] = payload.data;
      }

      return { ...state, newCalculation: tempNewCalc };
    case CALC_CONST_DELETE:
      let tempNewCalcDelete = { ...state.newCalculation };

      tempNewCalcDelete.constant = tempNewCalcDelete.constant.filter(
        (item) => item.name !== payload.name
      );

      return { ...state, newCalculation: tempNewCalcDelete };
    case CALC_NEWOUTPUTMODAL_CLOSE:
      return {
        ...state,
        createOutputModalVis: false,
        createOutputModalEdit: null,
      };
    case CALC_NEWOUTPUTMODAL_OPEN:
      if (payload)
        return {
          ...state,
          createOutputModalVis: true,
          createOutputModalEdit: payload,
        };
      else
        return {
          ...state,
          createOutputModalVis: true,
          createOutputModalEdit: null,
        };
    case CALC_NEWSTEPMODAL_CLOSE:
      return {
        ...state,
        newStepModalVis: false,
        newStepEdit: false,
        newStepEditData: null,
      };
    case CALC_NEWSTEPMODAL_OPEN:
      if (payload)
        return {
          ...state,
          newStepModalVis: true,
          newStepEdit: true,
          newStepEditData: payload,
        };
      else return { ...state, newStepModalVis: true };
    case CALC_CONSTMODAL_OPEN:
      return { ...state, constantModalData: payload, constantModalVis: true };
    case CALC_CONSTMODAL_CLOSE:
      return { ...state, constantModalData: null, constantModalVis: false };
    case CALC_NEWMODAL_OPEN:
      if (payload)
        return {
          ...state,
          createNewModalVis: true,
          createNewModalEdit: true,
          newCalculation: payload,
        };
      else
        return {
          ...state,
          createNewModalVis: true,
          createNewModalEdit: false,
          newCalculation: null,
        };
    case CALC_NEWMODAL_CLOSED:
      return {
        ...state,
        createNewModalVis: false,
        createNewModalEdit: false,
        newCalculation: null,
      };
    case CALC_GET_ALL:
      return { ...state, allCalcs: payload };
    case CALC_LOADING:
      return { ...state, loading: payload };
    case CALC_UPDATE_LOADING:
      return { ...state, updateLoading: payload };
    case CALC_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    default:
      return state;
  }
}
