import axios from "axios";
import { setAlert } from "../alert";
import setAuthToken from "../../utils/setAuthToken";
import { logout } from "../auth";

import {
  CONFIGTYPE_CLEARALL,
  CONFIGTYPE_GET_ALL,
  CONFIGTYPE_GET_ALL_DETAILED,
  CONFIGTYPE_GET_SINGLE,
  CONFIGTYPE_LOADING,
  CONFIGTYPE_MODALNEWITEM_CLOSE,
  CONFIGTYPE_MODALNEWITEM_OPEN,
  CONFIGTYPE_MODALNEW_CLOSE,
  CONFIGTYPE_MODALNEW_OPEN,
} from "../types";

// Get all the items
export const loadAllTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: CONFIGTYPE_LOADING,
      payload: true,
    });

    // get the all the items
    let res = await axios.get("/api/config/types/min");

    dispatch({
      type: CONFIGTYPE_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: CONFIGTYPE_LOADING,
      payload: false,
    });
  }
};

// Get all the items
export const loadAllTypesDetailed = () => async (dispatch) => {
  try {
    dispatch({
      type: CONFIGTYPE_LOADING,
      payload: true,
    });

    // get the all the items
    let res = await axios.get("/api/config/types");

    dispatch({
      type: CONFIGTYPE_GET_ALL_DETAILED,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: CONFIGTYPE_LOADING,
      payload: false,
    });
  }
};

// loads a specific item
export const loadATypes = (ID) => async (dispatch) => {
  try {
    if (ID) {
      // get the all the items
      let res = await axios.get("/api/config/types/" + ID);

      dispatch({
        type: CONFIGTYPE_GET_SINGLE,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
  }
};

// Edits a specific item
export const editATypeLocal = (data) => async (dispatch) => {
  try {
    if (data) {
      dispatch({
        type: CONFIGTYPE_GET_SINGLE,
        payload: data,
      });
    }
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
  }
};

// clears all the items
export const clearStateTypes = () => (dispatch) => {
  dispatch({
    type: CONFIGTYPE_CLEARALL,
  });
};

// delets a specific type item, needs the object id
export const DeleteSpecificType = (ID) => async (dispatch) => {
  try {
    if (ID) {
      dispatch({
        type: CONFIGTYPE_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the item
      await axios.delete("/api/config/types/" + ID);

      // get all the items again
      let res = await axios.get("/api/config/types/min");

      dispatch(setAlert("Type is deleted successful", "success"));

      dispatch({
        type: CONFIGTYPE_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log("DeleteSpecificType error: ", error.response);
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: CONFIGTYPE_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: CONFIGTYPE_LOADING,
        payload: false,
      });
    }
  }
};

// updates a specific type data, needs the  object id
export const UpdateSpecificType = (ID, data) => async (dispatch) => {
  try {
    if (ID && data) {
      dispatch({
        type: CONFIGTYPE_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the basic user information
      let res = await axios.put("/api/config/types/" + ID, { data });

      // get the sites information
      res = await axios.get("/api/config/types/min");

      dispatch(setAlert("Type details saved", "success"));

      dispatch({
        type: CONFIGTYPE_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log("UpdateSpecificType error: ", error.response);
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: CONFIGTYPE_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: CONFIGTYPE_LOADING,
        payload: false,
      });
    }
  }
};

// open modal
export const ModalNewTypeOpen = (data) => (dispatch) => {
  dispatch({
    type: CONFIGTYPE_MODALNEW_OPEN,
    payload: data,
  });
};

// close modal
export const ModalNewTypeClose = () => (dispatch) => {
  dispatch({
    type: CONFIGTYPE_MODALNEW_CLOSE,
  });
};

// open modal - Add new type item
export const ModalNewTypeItemOpen = (data) => (dispatch) => {
  dispatch({
    type: CONFIGTYPE_MODALNEWITEM_OPEN,
    payload: data,
  });
};

// close modal - Add new type item
export const ModalNewTypeItemClose = () => (dispatch) => {
  dispatch({
    type: CONFIGTYPE_MODALNEWITEM_CLOSE,
  });
};

// Creat new
export const createNewType = (data) => async (dispatch) => {
  try {
    if (data) {
      dispatch({
        type: CONFIGTYPE_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // create the item
      await axios.post("/api/config/types", { data });

      // get the all the items
      let res = await axios.get("/api/config/types/min");

      dispatch({
        type: CONFIGTYPE_GET_ALL,
        payload: res.data,
      });

      dispatch(setAlert("Type is added successful", "success"));
    }
  } catch (error) {
    dispatch({
      type: CONFIGTYPE_LOADING,
      payload: false,
    });

    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else if (error?.response?.status === 400) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }

    console.log("createNewType error: ", error.response);
  }
};
