import {
  CONFIGTEMP_CLEARALL,
  CONFIGTEMP_GET_ALL,
  CONFIGTEMP_GET_SINGLE,
  CONFIGTEMP_LOADING,
  CONFIGTEMP_MODALADDRESS_CLOSE,
  CONFIGTEMP_MODALADDRESS_OPEN,
  CONFIGTEMP_MODALALARMCOPY_CLOSE,
  CONFIGTEMP_MODALALARMCOPY_OPEN,
  CONFIGTEMP_MODALALARM_CLOSE,
  CONFIGTEMP_MODALALARM_OPEN,
  CONFIGTEMP_MODALBATCH_CLOSE,
  CONFIGTEMP_MODALBATCH_OPEN,
  CONFIGTEMP_MODALCALC_CLOSE,
  CONFIGTEMP_MODALCALC_OPEN,
  CONFIGTEMP_MODALEXTRADETAILS_CLOSE,
  CONFIGTEMP_MODALEXTRADETAILS_OPEN,
  CONFIGTEMP_MODALNEWBACNET_CLOSE,
  CONFIGTEMP_MODALNEWBACNET_OPEN,
  CONFIGTEMP_MODALNEWINVERTASCII_CLOSE,
  CONFIGTEMP_MODALNEWINVERTASCII_OPEN,
  CONFIGTEMP_MODALNEWRF868_CLOSE,
  CONFIGTEMP_MODALNEWRF868_OPEN,
  CONFIGTEMP_MODALNEWSNMP_CLOSE,
  CONFIGTEMP_MODALNEWSNMP_OPEN,
  CONFIGTEMP_MODALNEW_CLOSE,
  CONFIGTEMP_MODALNEW_OPEN,
  CONFIGTEMP_MODAL_IMPORT,
  TEMP_CALC_MODALCONST_CLOSE,
  TEMP_CALC_MODALCONST_OPEN,
  TEMP_CALC_MODALOUTPUT_CLOSE,
  TEMP_CALC_MODALOUTPUT_OPEN,
  TEMP_CALC_MODALSTEP_CLOSE,
  TEMP_CALC_MODALSTEP_OPEN,
  TEMP_CALC_NEWCALC,
} from "../../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  modalVis: false,
  modalBatchaVis: false,
  modalAddressVis: false,
  modalAlarmVis: false,
  modalAlarmCopyVis: false,
  modalBatchData: null,
  modalAddressData: null,
  modalAlarmData: null,
  modalAlarmCopyData: null,
  isEdit: false,
  modalSNMPData: null,
  modalSNMPVis: null,
  modalExtraDetailsVis: false,
  modalExtraDetailsData: null,
  modalImportTemplateVis: false,
  modalBacnetVis: false,
  modalBacnetData: null,
  modalCalcData: null,
  modalCalcVis: false,
  modalRF868Data: null,
  modalRF868Vis: false,
  modalASCIIData: null,
  modalASCIIVis: false,
  createOutputModalVis: false,
  createOutputModalEdit: null,
  constantModalVis: false,
  constantModalData: null,
  newStepModalVis: false,
  newStepEditData: null,
  newCalculation: null,
};

export default function templates(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case TEMP_CALC_NEWCALC:
      return {
        ...state,
        newCalculation: payload,
      };
    case TEMP_CALC_MODALSTEP_CLOSE:
      return {
        ...state,
        newStepModalVis: false,
        newStepEditData: null,
      };
    case TEMP_CALC_MODALSTEP_OPEN:
      return {
        ...state,
        newStepModalVis: true,
        newStepEditData: payload,
      };
    case TEMP_CALC_MODALCONST_CLOSE:
      return {
        ...state,
        constantModalVis: false,
        constantModalData: null,
      };
    case TEMP_CALC_MODALCONST_OPEN:
      return {
        ...state,
        constantModalVis: true,
        constantModalData: payload,
      };
    case CONFIGTEMP_MODALCALC_CLOSE:
      return {
        ...state,
        modalCalcVis: false,
        modalCalcData: null,
      };
    case CONFIGTEMP_MODALCALC_OPEN:
      return {
        ...state,
        modalCalcVis: true,
        modalCalcData: payload,
      };
    case TEMP_CALC_MODALOUTPUT_CLOSE:
      return {
        ...state,
        createOutputModalVis: false,
        createOutputModalEdit: null,
      };
    case TEMP_CALC_MODALOUTPUT_OPEN:
      return {
        ...state,
        createOutputModalVis: true,
        createOutputModalEdit: payload,
      };
    case CONFIGTEMP_MODALNEWRF868_CLOSE:
      return {
        ...state,
        modalRF868Vis: false,
        modalRF868Data: null,
      };
    case CONFIGTEMP_MODALNEWRF868_OPEN:
      return {
        ...state,
        modalRF868Vis: true,
        modalRF868Data: payload,
      };
    case CONFIGTEMP_MODALNEWINVERTASCII_CLOSE:
      return {
        ...state,
        modalASCIIVis: false,
        modalASCIIData: null,
      };
    case CONFIGTEMP_MODALNEWINVERTASCII_OPEN:
      return {
        ...state,
        modalASCIIVis: true,
        modalASCIIData: payload,
      };
    case CONFIGTEMP_MODALNEWBACNET_CLOSE:
      return {
        ...state,
        modalBacnetVis: false,
        modalBacnetData: null,
      };
    case CONFIGTEMP_MODALNEWBACNET_OPEN:
      return {
        ...state,
        modalBacnetVis: true,
        modalBacnetData: payload,
      };
    case CONFIGTEMP_MODAL_IMPORT:
      return {
        ...state,
        modalImportTemplateVis: payload,
      };
    case CONFIGTEMP_GET_SINGLE:
      return {
        ...state,
        editData: { ...payload },
      };
    case CONFIGTEMP_MODALNEW_CLOSE:
      return {
        ...state,
        modalVis: false,
        editData: null,
        isEdit: false,
      };
    case CONFIGTEMP_MODALALARMCOPY_OPEN:
      return {
        ...state,
        modalAlarmCopyVis: true,
        modalAlarmCopyData: payload,
      };
    case CONFIGTEMP_MODALALARMCOPY_CLOSE:
      return {
        ...state,
        modalAlarmCopyVis: false,
        modalAlarmCopyData: null,
      };
    case CONFIGTEMP_MODALALARM_OPEN:
      return {
        ...state,
        modalAlarmVis: true,
        modalAlarmData: payload,
      };
    case CONFIGTEMP_MODALALARM_CLOSE:
      return {
        ...state,
        modalAlarmVis: false,
        modalAlarmData: null,
      };
    case CONFIGTEMP_MODALADDRESS_OPEN:
      return {
        ...state,
        modalAddressVis: true,
        modalAddressData: payload,
      };
    case CONFIGTEMP_MODALNEWSNMP_CLOSE:
      return {
        ...state,
        modalSNMPVis: false,
        modalSNMPData: null,
      };
    case CONFIGTEMP_MODALEXTRADETAILS_CLOSE:
      return {
        ...state,
        modalExtraDetailsVis: false,
        modalExtraDetailsData: null,
      };
    case CONFIGTEMP_MODALEXTRADETAILS_OPEN:
      return {
        ...state,
        modalExtraDetailsVis: true,
        modalExtraDetailsData: payload,
      };
    case CONFIGTEMP_MODALNEWSNMP_OPEN:
      return {
        ...state,
        modalSNMPVis: true,
        modalSNMPData: payload,
      };
    case CONFIGTEMP_MODALADDRESS_CLOSE:
      return {
        ...state,
        modalAddressVis: false,
        modalAddressData: null,
      };
    case CONFIGTEMP_MODALBATCH_OPEN:
      return {
        ...state,
        modalBatchaVis: true,
        modalBatchData: payload,
      };
    case CONFIGTEMP_MODALBATCH_CLOSE:
      return {
        ...state,
        modalBatchaVis: false,
        modalBatchData: null,
      };
    case CONFIGTEMP_MODALNEW_OPEN:
      return {
        ...state,
        modalVis: true,
        isEdit: payload !== null ? true : false,
      };
    case CONFIGTEMP_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    case CONFIGTEMP_GET_ALL:
      return {
        ...state,
        loading: false,
        data: payload,
      };
    case CONFIGTEMP_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
}
