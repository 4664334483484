import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd componetn
import { Popconfirm, Space, Table, Tag } from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";

// external functions
import {
  createNewModalOpen,
  deleteCalculation,
} from "../../../../../actions/staffTools/calculations";

// acual componet
const TableComponent = ({
  tabSelected,
  allCalcs,
  loading,
  createNewModalOpen,
  deleteCalculation,
}) => {
  const handleEdit = (record) => {
    createNewModalOpen(record);
  };

  const handleDelete = (record) => {
    deleteCalculation(record);
  };

  const columns = [
    {
      title: "Name",
      key: "Name",
      dataIndex: "calculationName",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.calculationName.localeCompare(b.calculationName),
      render: (text, record) => (
        <a
          style={{ margin: 0, fontSize: 11 }}
          href="#!"
          onClick={() => handleEdit(record)}
        >
          {text}
          {(new Date().getTime() - new Date(record.createdAt)) /
            (1000 * 60 * 60 * 24) <
            30 && (
            <Tag
              color="green"
              style={{
                fontSize: 9,
                padding: 0,
                borderWidth: 0,
                top: 5,
                position: "absolute",
              }}
            >
              New
            </Tag>
          )}
        </a>
      ),
    },
    {
      title: "Description",
      key: "Description",
      dataIndex: "calculationDescription",
      render: (text, record) => (
        <p style={{ margin: 0, fontSize: 11 }}>{text}</p>
      ),
    },
    {
      title: "Created By",
      key: "CreatedBy",
      dataIndex: "createdBy",
      width: 200,
    },
    {
      title: "Updated By",
      key: "UpdatedBy",
      dataIndex: "updatedBy",
      width: 200,
    },
    {
      title: "Created At",
      key: "CreatedAt",
      width: 150,
      responsive: ["md"],
      dataIndex: "createdAt",
      render: (text) => (
        <p style={{ margin: 0, fontSize: 11 }}>
          {new Date(text).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Updated At",
      key: "UpdatedAt",
      width: 150,
      responsive: ["md"],
      dataIndex: "updatedAt",
      render: (text) => (
        <p style={{ margin: 0, fontSize: 11 }}>
          {new Date(text).toLocaleString()}
        </p>
      ),
    },
    {
      title: "",
      width: "90px",
      dataIndex: "operation",
      render: (text, record) => (
        <Space size="middle">
          <Fragment>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <a href="#!">
                <DeleteFilled style={{ color: "red" }} />
              </a>
            </Popconfirm>
            <a href="#!" onClick={() => handleEdit(record)}>
              <EditFilled />
            </a>
          </Fragment>
        </Space>
      ),
    },
  ];

  return (
    <Table
      bordered
      style={{ marginTop: "10px" }}
      size={"small"}
      scroll={320}
      dataSource={
        tabSelected === "1"
          ? allCalcs &&
            allCalcs
              ?.filter((calc) => calc?.DeviceType === true)
              .map((item, index) => {
                return { ...item, key: index };
              })
          : tabSelected === "2"
          ? allCalcs &&
            allCalcs
              ?.filter((calc) => calc?.StatsType === true)
              .map((item, index) => {
                return { ...item, key: index };
              })
          : allCalcs &&
            allCalcs
              ?.filter((calc) => calc?.isVirtual === true)
              .map((item, index) => {
                return { ...item, key: index };
              })
      }
      columns={columns}
      loading={loading}
      pagination={{
        defaultPageSize: 30,
        showSizeChanger: true,
        pageSizeOptions: ["30", "40", "50"],
      }}
    />
  );
};

TableComponent.propTypes = {
  allCalcs: PropTypes.array,
  loading: PropTypes.bool,
  createNewModalOpen: PropTypes.func,
  deleteCalculation: PropTypes.func,
};

const mapStateToProps = (state) => ({
  allCalcs: state.calcs.allCalcs,
  loading: state.calcs.loading,
});

export default React.memo(
  connect(mapStateToProps, { createNewModalOpen, deleteCalculation })(
    TableComponent
  )
);
